//Calendar Styles
.view .date-nav-wrapper .date-prev{
  right: 119px;
  
  @include breakpoint($xs) {
    right: auto;
  }
}

.view .date-nav-wrapper .date-heading h3{
  text-align: left;
  padding-top: 8px;
}

.view-id-Events_Cal{
  margin-top: 20px;
}

.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next{
  background-color: #fff;
  border-radius: 8px;

  position: relative;
  @include breakpoint($sm) {
    position: absolute;
  }

  a{
    background-color: $wcm-dark-orange;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0 35px;
    height: 30px;
    line-height: 30px;
    margin: 0;
  }
}

.calendar-calendar th.days{
  background-color: #f0f1f2;
}

.calendar-calendar .views-field-field-date-and-time{
  float: left;
  padding-right: 5px!important;
}

.calendar-calendar .month-view .full td.single-day div.monthview{
  border-radius: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  div.contents{
    padding-bottom: 9px;
  }
}

.calendar-calendar .month-view .full tr td.multi-day, .calendar-calendar .month-view .full tr td.single-day{
  border-radius: 0px;
  padding: 0;
  margin: 0;
}

.calendar-calendar .views-field-title{
  float: left;
}


.calendar-calendar .month-view div.content{
  margin-bottom: 9px;
}

.lf-ghost{
  height: auto!important;
}

div.modal-forms-modal-content .popups-close{
  background: url(../images/close.png) 0 0 no-repeat;
  &:hover{
    background: url(../images/close.png) 0 0 no-repeat;
  }
}

.close{
  opacity: 1;
  &:hover{
    opacity: 1;
  }
}

.view-content .calendar-calendar{
  margin-bottom: 20px;
}


#reserach-home-upper-left{
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  .view-teaser{
    width: 100%;
    @include breakpoint($sm){
      width: 33.3333%;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      &:first-child{
        padding-left: 0;
        padding-right: 10px;
      }
      &:last-child{
        padding-right: 0;
        padding-left: 10px;
      }
    }
  }
}

.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_1 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_5 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar{
  width: 100%!important;
  border: 0;
  border-radius: 0;
  margin-left: 0;
  
}

.calendar-calendar .day-view .full div.calendar-agenda-hour, .calendar-calendar .day-view .full div.calendar-agenda-items{
  height: 74px;
}

.calendar-calendar div.single-day .d_4 .view-item .calendar {
    height: 73px;
}

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour{
  height: 37px;
}

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour{
  border-bottom: none;
}

.calendar-calendar .week-view .full div.item-wrapper, .calendar-calendar .day-view .full div.item-wrapper{
   margin-top: -37px;
}

/* =Week View Adjustments - WEBCTSC-142
----------------------------------------------------------*/

.calendar-calendar .week-view .full div.calendar-agenda-hour {
  height: 150px;
}

.calendar-calendar div.single-day .d_4 .view-item .calendar.weekview {
  height: 150px;
}

.calendar-calendar .week-view .full div.calendar-agenda-items {
  height: 150px;
}

.calendar-calendar .week-view .full div.single-day div.weekview {
  overflow: visible;
}

.calendar-calendar .week-view .full td .inner div.calendar.weekview div {
  background: #ffc;
}

/*
----------------------------------------------------------*/

.calendar-calendar div.single-day .o_0 .view-item {
  top: 0px;
}

.calendar-calendar div.single-day .o_1 .view-item {
  top: 18px;
}

.calendar-calendar div.single-day .o_2 .view-item {
  top: 37px;
}

.calendar-calendar div.single-day .o_3 .view-item {
  top: 55px;
}

.calendar-calendar div.single-day .o_4 .view-item {
  top: 73px;
}

.calendar-calendar div.single-day .d_1 .view-item .calendar {
  height: 18px;
}

.calendar-calendar div.single-day .d_2 .view-item .calendar {
  height: 37px;
}

.calendar-calendar div.single-day .d_3 .view-item .calendar {
  height: 55px;
}

.calendar-calendar div.single-day .d_4 .view-item .calendar {
  height: 74px;
}

.calendar-calendar div.single-day .d_5 .view-item .calendar {
  height: 92px;
}

.calendar-calendar div.single-day .d_6 .view-item .calendar {
  height: 111px;
}

.calendar-calendar div.single-day .d_7 .view-item .calendar {
  height: 129px;
}

.calendar-calendar div.single-day .d_8 .view-item .calendar {
  height: 148px;
}

.calendar-calendar div.single-day .d_9 .view-item .calendar {
  height: 166px;
}

.calendar-calendar div.single-day .d_10 .view-item .calendar {
  height: 185px;
}

.calendar-calendar div.single-day .d_11 .view-item .calendar {
  height: 203px;
}

.calendar-calendar div.single-day .d_12 .view-item .calendar {
  height: 222px;
}

.calendar-calendar div.single-day .d_13 .view-item .calendar {
  height: 240px;
}

.calendar-calendar div.single-day .d_14 .view-item .calendar {
  height: 259px;
}

.calendar-calendar div.single-day .d_15 .view-item .calendar {
  height: 277px;
}

.calendar-calendar div.single-day .d_16 .view-item .calendar {
  height: 296px;
}

.calendar-calendar div.single-day .d_17 .view-item .calendar {
  height: 314px;
}

.calendar-calendar div.single-day .d_18 .view-item .calendar {
  height: 333px;
}

.calendar-calendar div.single-day .d_19 .view-item .calendar {
  height: 351px;
}

.calendar-calendar div.single-day .d_20 .view-item .calendar {
  height: 370px;
}

.calendar-calendar div.single-day .d_21 .view-item .calendar {
  height: 388px;
}

.calendar-calendar div.single-day .d_22 .view-item .calendar {
  height: 407px;
}

.calendar-calendar div.single-day .d_23 .view-item .calendar {
  height: 425px;
}

.calendar-calendar div.single-day .d_24 .view-item .calendar {
  height: 444px;
}

.calendar-calendar div.single-day .d_25 .view-item .calendar {
  height: 462px;
}

.calendar-calendar div.single-day .d_26 .view-item .calendar {
  height: 481px;
}

.calendar-calendar div.single-day .d_27 .view-item .calendar {
  height: 499px;
}

.calendar-calendar div.single-day .d_28 .view-item .calendar {
  height: 518px;
}

.calendar-calendar div.single-day .d_29 .view-item .calendar {
  height: 536px;
}

.calendar-calendar div.single-day .d_30 .view-item .calendar {
  height: 555px;
}

.calendar-calendar div.single-day .d_31 .view-item .calendar {
  height: 573px;
}

.calendar-calendar div.single-day .d_32 .view-item .calendar {
  height: 592px;
}

.calendar-calendar div.single-day .d_33 .view-item .calendar {
  height: 610px;
}

.calendar-calendar div.single-day .d_34 .view-item .calendar {
  height: 626px;
}

.calendar-calendar div.single-day .d_35 .view-item .calendar {
  height: 647px;
}

.calendar-calendar div.single-day .d_36 .view-item .calendar {
  height: 666px;
}

.calendar-calendar div.single-day .d_37 .view-item .calendar {
  height: 684px;
}

.calendar-calendar div.single-day .d_38 .view-item .calendar {
  height: 700px;
}

.calendar-calendar div.single-day .d_39 .view-item .calendar {
  height: 721px;
}

.calendar-calendar div.single-day .d_40 .view-item .calendar {
  height: 740px;
}

.calendar-calendar div.single-day .d_41 .view-item .calendar {
  height: 490px;
}

.calendar-calendar div.single-day .d_42 .view-item .calendar {
  height: 502px;
}

.calendar-calendar div.single-day .d_43 .view-item .calendar {
  height: 514px;
}

.calendar-calendar div.single-day .d_44 .view-item .calendar {
  height: 526px;
}

.calendar-calendar div.single-day .d_45 .view-item .calendar {
  height: 538px;
}

.calendar-calendar div.single-day .d_46 .view-item .calendar {
  height: 550px;
}

.calendar-calendar div.single-day .d_47 .view-item .calendar {
  height: 562px;
}

.calendar-calendar div.single-day .d_48 .view-item .calendar {
  height: 574px;
}

.calendar-calendar div.single-day .d_49 .view-item .calendar {
  height: 586px;
}

.calendar-calendar div.single-day .d_50 .view-item .calendar {
  height: 598px;
}

.calendar-calendar div.single-day .d_51 .view-item .calendar {
  height: 610px;
}

.calendar-calendar div.single-day .d_52 .view-item .calendar {
  height: 622px;
}

.calendar-calendar div.single-day .d_53 .view-item .calendar {
  height: 634px;
}

.calendar-calendar div.single-day .d_54 .view-item .calendar {
  height: 646px;
}

.calendar-calendar div.single-day .d_55 .view-item .calendar {
  height: 658px;
}

.calendar-calendar div.single-day .d_56 .view-item .calendar {
  height: 670px;
}

.calendar-calendar div.single-day .d_57 .view-item .calendar {
  height: 682px;
}

.calendar-calendar div.single-day .d_58 .view-item .calendar {
  height: 694px;
}

.calendar-calendar div.single-day .d_59 .view-item .calendar {
  height: 706px;
}

.calendar-calendar div.single-day .d_60 .view-item .calendar {
  height: 718px;
}

.calendar-calendar div.single-day .d_61 .view-item .calendar {
  height: 730px;
}

.calendar-calendar div.single-day .d_62 .view-item .calendar {
  height: 742px;
}

.calendar-calendar div.single-day .d_63 .view-item .calendar {
  height: 754px;
}

.calendar-calendar div.single-day .d_64 .view-item .calendar {
  height: 766px;
}

.calendar-calendar div.single-day .d_65 .view-item .calendar {
  height: 778px;
}

.calendar-calendar div.single-day .d_66 .view-item .calendar {
  height: 790px;
}

.calendar-calendar div.single-day .d_67 .view-item .calendar {
  height: 802px;
}

.calendar-calendar div.single-day .d_68 .view-item .calendar {
  height: 814px;
}

.calendar-calendar div.single-day .d_69 .view-item .calendar {
  height: 826px;
}

.calendar-calendar div.single-day .d_70 .view-item .calendar {
  height: 838px;
}

.calendar-calendar div.single-day .d_71 .view-item .calendar {
  height: 850px;
}

.calendar-calendar div.single-day .d_72 .view-item .calendar {
  height: 862px;
}

.calendar-calendar div.single-day .d_73 .view-item .calendar {
  height: 874px;
}

.calendar-calendar div.single-day .d_74 .view-item .calendar {
  height: 886px;
}

.calendar-calendar div.single-day .d_75 .view-item .calendar {
  height: 898px;
}

.calendar-calendar div.single-day .d_76 .view-item .calendar {
  height: 910px;
}

.calendar-calendar div.single-day .d_77 .view-item .calendar {
  height: 922px;
}

.calendar-calendar div.single-day .d_78 .view-item .calendar {
  height: 934px;
}

.calendar-calendar div.single-day .d_79 .view-item .calendar {
  height: 946px;
}

.calendar-calendar div.single-day .d_80 .view-item .calendar {
  height: 958px;
}

.calendar-calendar div.single-day .d_81 .view-item .calendar {
  height: 970px;
}

.calendar-calendar div.single-day .d_82 .view-item .calendar {
  height: 982px;
}

.calendar-calendar div.single-day .d_83 .view-item .calendar {
  height: 994px;
}

.calendar-calendar div.single-day .d_84 .view-item .calendar {
  height: 1006px;
}

.calendar-calendar div.single-day .d_85 .view-item .calendar {
  height: 1018px;
}

.calendar-calendar div.single-day .d_86 .view-item .calendar {
  height: 1030px;
}

.calendar-calendar div.single-day .d_87 .view-item .calendar {
  height: 1042px;
}

.calendar-calendar div.single-day .d_88 .view-item .calendar {
  height: 1054px;
}

.calendar-calendar div.single-day .d_89 .view-item .calendar {
  height: 1066px;
}

.calendar-calendar div.single-day .d_90 .view-item .calendar {
  height: 1078px;
}

.calendar-calendar div.single-day .d_91 .view-item .calendar {
  height: 1090px;
}

.calendar-calendar div.single-day .d_92 .view-item .calendar {
  height: 1102px;
}

.calendar-calendar div.single-day .d_93 .view-item .calendar {
  height: 1114px;
}

.calendar-calendar div.single-day .d_94 .view-item .calendar {
  height: 1128px;
}

.calendar-calendar div.single-day .d_95 .view-item .calendar {
  height: 1140px;
}

.calendar-calendar div.single-day .d_96 .view-item .calendar {
  height: 1152px;
}

.evntlink{
  line-height: 2.0;
  margin: 25px auto 0 ;
}
.caltabs{
  margin-top: 15px;
}

.views-widget-filter-field_event_category_tid .select2-container{
  min-width: 210px;
}

.views-exposed-form .views-widget-filter-field_event_location_tid_selective{
  padding: 0 0 10px 0;
}

label[for='edit-field-event-location-tid-selective']{
  float: left;
  margin: 0 20px 0 0;
  line-height: 50px;
}

.views-widget-filter-field_event_location_tid_selective{
  float: none;
  .views-widget{
    float: left;
  }
}

#views-exposed-form-Events-Cal-panel-pane-1{
  .views-submit-button{
    padding: 0;
  }
  .views-exposed-widgets{
    margin-bottom: 0px;
  }
}

.pane-views-exp-events-cal-panel-pane-1{
  margin-bottom: 0!important;
}

.calendar-calendar .week-view .full .days {
    width: 14%;
}

th.margin-right, td.margin-right{
  display: none;
  width: 0;
}

.calendar-calendar .week-view .full td:nth-child(2){
   width: 14.1%;
}

.view-id-news.view-display-id-panel_pane_1>.view-content{
  max-height: 710px;
  overflow: scroll;
}

.calendar-calendar td .inner div.day {
  font-size: 13px;
}

.calendar-calendar td .inner div.day a{
   pointer-events: none;
   cursor: default;
   color: inherit;
   font-weight: normal;
}

.calendar-calendar .month-view .full td.date-box.today, .calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
    border-color: #e87722;
}

.calendar-calendar .month-view .full tr td.single-day.today{
  border-width: 4px;
}
.calendar-calendar .month-view .full td.date-box.today {
    border-width: 4px 4px 0px 4px;
  }

.date-display-single{
  margin-right: 4px;
}

.pane-events-calendar-panel-pane-1, .pane-events-calendar-panel-pane-2{
  float: left;
  width: 100%;

  .view-content {
    .calendar-calendar {
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
        -webkit-overflow-scrolling: touch;
    }
  }
}

.calendar-calendar .month-view .full td.single-day div.monthview{
  padding: 13px;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty, 
.calendar-calendar .month-view .full td.single-day.empty, 
.calendar-calendar .month-view .full td.date-box.empty{
  background-color: #fff;
  .day{
    color: #d1d2d4;
  }
}

.calendar-calendar th.days{
  background-color: #fff;
  border: 0;
  border-top: 7px solid #e8e9ea;
  height: 38px;
  line-height: 38px;
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-weight: normal;
}

#edit-field-event-category-tid-wrapper{
  margin-bottom: 10px;
  @include breakpoint($md) {
    float: right;
    margin-bottom: 0;
  }
  padding-right: 0;
  position: relative;
  padding-top: 0;
  // top: 50px;
  label{
    float: left;
    font-weight: normal;
    line-height: 43px;
    height: 43px;
    margin: 0 20px 0 0;
  }
  .views-widget{
    float: left;
  }
}

.weekmonthtabs{
  ul{
    width: 100%;
    float: left;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #d7d8d9;
    li{
      float: left;
      width: 216px;
      height: 60px;
      text-align: center;
      a{
        line-height: 60px;
        height: 60px;
        width: 100%;
        float: left;
        color: #d5d6d7;
        font-weight: 600;
        font-size: 22px;
        border-top: 4px solid #d7d8d9;
        border-right: 1px solid #d7d8d9;
        background-color: #f5f6f7;
      }

      a.active span{
        display: none;
      }
      a.active{
        top: 1px;
        height: 61px;
        background-color: #fff;
        color: #000;
      }
    }
  }
}

#views-exposed-form-events-calendar-panel-pane-1, #views-exposed-form-events-calendar-panel-pane-2 {
  @include breakpoint($md) {
    float: right;
    // margin-top: -60px;
    height: 0;
  }
}

.calendar-calendar .month-view .full td.single-day .inner>div {
    border-left: 5px solid #fdc326;
}

.calendar-calendar td span.date-display-single, .calendar-calendar td span.date-display-start, .calendar-calendar td span.date-display-end, .calendar-calendar td span.date-display-separator, .calendar-calendar td a {
    font-weight: normal;
    font-size: 13px;
    display: inline;
}

.today .inner>div {
    border-left: none !important;
}
