#primary-nav .level-1 {
	width: 16.66667%;
	padding: 8px 1.6%;

	@include breakpoint($md) {
		width: auto;
		padding: 8px 0.83%;
	}

	@include breakpoint($lg) {
		padding: 8px 2.14%;
	}
}

#drawer-nav .level-2 {
	padding-right: 15px;
}

#drawer-nav .level-2.menu-mlid-1950 {
	@include breakpoint($md) {
		width: 22%;
	}
}

.block-menu-block .content {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	-webkit-column-gap: 15px;
	-moz-column-gap: 15px;
	column-gap: 15px;
}

.brand__lockup img {
	max-height: 84px;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
}

/* Homepage Style */

.tagline {
	margin: 25px 0 8px;

	p {
		text-align: center;
		line-height: 36px;
		font-size: 20px;
		color: #777;
	}
}

.socrow {
	margin: 0 auto;
	width: 205px;

	a {
		border-bottom: none;

		&:last-child {
			span {
				margin-right: 0;
			}
		}
	}

	svg {
		width: 28px;
		height: 28px;
		fill: $wcm-white;
		float: left;
	}

	span {
		font-size: 14px;
		height: 28px;
		margin-right: 26px;
		margin-left: 6px;
		float: left;
	}
}

/* Homepage Tabs */

.pane-homepage-cta-tabs-panel-pane-2 {
	//btns
	display: block;
	float: left;
	width: 100%;
	margin-top: -30px;
	top: 60px;
	position: relative;
	z-index: 100;

	.view-content {
		width: 100%;
		margin: 0 auto;
	}

	.lnk-row {
		background-color: $wcm-dark-orange;
		float: left;
		width: 100%;
		padding: 0 20px;
		position: relative;

		@include breakpoint($sm) {
			padding: 0 55px;
		}

		@include breakpoint($md) {
			width: 11%;
			padding: 0;

			&:nth-child(2) {
				width: 18%;
			}

			&:nth-child(3) {
				width: 33%;
			}

			&:nth-child(4) {
				width: 38%;
			}
		}

		@include breakpoint($lg) {
			width: 18%;
			padding: 0;

			&:nth-child(3) {
				width: 30%;
			}

			&:nth-child(4) {
				width: 34%;
			}
		}

		&:last-child {
			a {
				border-right: 0;
			}
		}
	}

	.onlnk {
		background-color: $wcm-dark-orange;

		@include breakpoint($md) {
			background-color: transparent;

			&:after {
				content: ' ';
				width: 0;
				height: 0;
				border-left: 35px solid transparent;
				border-right: 35px solid transparent;
				border-top: 15px solid #fff;
				position: relative;
				margin: 0 auto;
				display: block;
			}
		}

		a {
			color: #fff;
		}

	}

	a {
		text-align: left;
		display: block;
		float: left;
		font-size: 16px;
		color: $wcm-yellow;
		line-height: 40px;
		padding: 12px 0 8px;
		width: 100%;
		border-bottom: 1px solid $wcm-red;

		@include breakpoint($md) {
			border-right: 1px solid $wcm-red;
			border-bottom: none;
			text-align: center;
		}
	}
}

.pane-homepage-cta-tabs-panel-pane-1 {
	//bodyies
	float: left;

	.view-content {
		div {
			&:first-child {
				.bodytb {
					display: block;
				}
			}
		}
	}

	.bodytb {
		display: none;
		min-height: 404px;
		background-size: cover;
		background-image: url("../img/homepage_researchers.jpg");
		background-position: center top;
		margin: 0;

		h2 {
			color: #fff;
			font-size: 38px;
			padding-top: 80px;

			@include breakpoint($sm) {
				padding-top: 70px;
			}

			@include breakpoint($md) {
				padding-left: 72px;
				padding-top: 140px;
			}
		}

		p {
			color: #fff;
			font-size: 21px;
			line-height: 36px;
			margin-bottom: 20px;

			@include breakpoint($md) {
				padding-left: 72px;
			}
		}

		.lefttxt,
		.cta--links {
			padding: 0 20px;

			@include breakpoint($sm) {
				padding: 0 55px;
			}

			@include breakpoint($md) {
				padding: 0 20px;
			}
		}

		.cta--links {
			min-height: 220px;
			margin-bottom: 20px;

			@include breakpoint($md) {
				padding-top: 0;
				margin-top: 120px;
				border-top: none;
				border-left: 1px solid $wcm-bright-orange;
			}

			a {
				font-size: 18px;
				line-height: 37px;
				width: 100%;
				display: block;
				color: $wcm-yellow;
				clear: both;

				&:after {
					content: '\e80d';
					color: $wcm-bright-orange;
					;
					padding-left: 10px;
					font-size: 12px;
					vertical-align: top;
					@include fontello();
					text-decoration: none;
					line-height: 3;
				}

				&:first-child {
					border-top: 1px solid $wcm-bright-orange;
					padding-top: 20px;

					&:before {
						position: absolute;
						top: 0px;
						left: 50%;
						content: ' ';
						width: 0;
						height: 0;
						border-top: 10px solid $wcm-bright-orange;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
					}

					@include breakpoint($md) {
						border-top: none;
						padding-top: 0;

						&:before {
							position: absolute;
							top: 95px;
							left: 0px;
							content: ' ';
							width: 0;
							height: 0;
							border-top: 10px solid transparent;
							border-bottom: 10px solid transparent;
							border-left: 10px solid $wcm-bright-orange;
						}
					}
				}
			}

		}
	}

	.imgurl {
		display: none;
	}
}

.btn-sml {
	border-radius: 8px;
	font-size: 13px;
	padding: 4px 32px;
}

.pane-news-panel-pane-5 {
	margin-bottom: 40px;
	float: left;

	.view {
		float: left;
	}

	.views-row {
		float: left;
		width: 100%;

		@include breakpoint($md) {
			border-right: 1px solid #f2f3f3;
			padding-right: 65px;
		}
	}

	h3.pane-title {
		font-size: 14px;
		text-transform: uppercase;
		color: #000;
		margin-top: 25px;
		margin-bottom: 20px;
		float: left;
		font-weight: 700;
	}

	h3 {
		font-size: 18px;
		line-height: 24px;
		width: 100%;
		float: left;
		//font-family: "Open Sans", sans-serif;
		margin: 0 0 8px;

		a {
			color: $wcm-dark-orange;
			margin: 0;

			&:after {
				line-height: 2.3;
			}
		}
	}

	.teaser-image {
		margin-bottom: 5px;

		img {
			width: 100%;
			// @include breakpoint($sm){
			// 	width: auto;
			// }
		}
	}

	p.post-date {
		font-size: 12px;
		text-transform: uppercase;
		color: #000;
		width: 100%;
		float: left;
		margin-bottom: 20px;
		font-weight: 600;
	}

	.view-footer {
		p {
			text-align: right;

			a {
				color: $wcm-bright-orange;
				margin-right: 45px;

				&:after {
					content: '\e80d';
					color: #e87722;
					padding-left: 10px;
					font-size: 60%;
					vertical-align: top;
					font-family: "fontello";
					font-style: normal;
					font-weight: normal;
					speak: none;
					display: inline-block;
					text-decoration: inherit;
					text-align: center;
					font-variant: normal;
					text-transform: none;
					text-decoration: none;
					line-height: 2.5;
				}
			}
		}

		@include breakpoint($md) {
			border-right: 1px solid #f2f3f3;
		}
	}
}

.pane-events-panel-pane-1,
.pane-events-panel-pane-2,
.pane-events-panel-pane-3,
.pane-events-panel-pane-4 {
	.views-row {
		width: 100%;
		float: left;
		margin: 15px 0;
	}

	h3.pane-title {
		float: left;
		font-size: 14px;
		text-transform: uppercase;
		color: #000;
		margin-top: 42px;
		margin-bottom: 20px;
		width: 25%;
		text-align: right;
		padding-right: 15px;
	}

	.views-field-title {
		float: left;
		width: 75%;
		display: table;
		height: 62px;

		h3 {
			display: table-cell;
			vertical-align: middle;
			margin-top: 0;
			padding-left: 15px;

			a {
				color: $wcm-dark-orange;
				font-size: 16px;

				&:after {
					top: 9px;
					position: relative;
				}
			}
		}
	}

	.views-field-field-event-category,
	.date-and-category {
		float: left;
		width: 25%;
		max-width: 150px;
		border-right: 1px solid #d7d8d9;
		min-height: 62px;

		a {
			color: #6b6c6d;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 18px;
			text-align: right;
			//padding-left: 25px;
			padding-right: 15px;
			float: left;
			width: 100%;
		}

		.date-display-single {
			color: #6b6c6d;
			text-transform: uppercase;
			font-size: 17px;
			line-height: 26px;
			font-weight: 600;
			text-align: right;
			padding-right: 15px;
			float: left;
			width: 100%;
		}
	}

	.view-footer {
		width: 100%;
		float: left;
		margin-bottom: 50px;

		a {
			color: $wcm-bright-orange;
			float: right;
			text-align: right;

			&:after {
				content: '\e80d';
				color: #e87722;
				padding-left: 10px;
				font-size: 60%;
				vertical-align: top;
				font-family: "fontello";
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				text-decoration: none;
				line-height: 2.5;
			}
		}
	}
}

.pane-events-panel-pane-2,
.pane-events-panel-pane-3,
.pane-events-panel-pane-4 {
	h3.pane-title {
		width: 100%;
		text-align: left;
		margin-top: 22px;
		color: $wcm-red;
		font-size: 19px;
		text-transform: none;
		margin-bottom: 0;
	}

	.views-field-field-event-category {
		width: 10%;
		min-width: 80px;
	}

	.view-footer a {
		float: left;
	}
}

.hottofw {
	background-color: #f6f7f8;
}

.howto-pane {
	width: 100%;
	float: left;
	margin-bottom: 21px;

	h3.pane-title {
		font-size: 14px;
		text-transform: uppercase;
		color: #000;
		margin-top: 29px;
		margin-bottom: 10px;
		text-align: center;
	}

	.ctsc-howto__tile {
		// margin-right: 20px;
		margin-right: 2%;
		width: 48%;
		float: left;
		background-color: #fff;
		height: 160px;
		font-size: 12px;
		line-height: 24px;
		backface-visibility: hidden;
		border: 3px solid #fff;
		margin-bottom: 10px;
		padding: 25px;

		@include breakpoint($md) {
			margin-right: 20px;
			width: 18%;
			padding: 25px 45px;
			margin: 9px 20px 9px 0;
		}

		@include breakpoint($lg) {
			width: 18.5%;
			padding: 25px 65px;
			margin: 9px 20px 9px 0;
		}

		&:last-child {
			margin-right: 0;

			margin-left: 25%;

			@include breakpoint($md) {
				margin-left: auto;
			}
		}

		&:hover {
			border: 3px solid $wcm-bright-orange;
			text-decoration: none;
		}

		.cta-icon {
			width: 50px;
			height: 50px;
			fill: #e87722;
			color: #cf4520;
			margin: 0 auto;
			display: block;
			float: none;
		}

		>span {
			display: inline-block;
			width: 100%;
			clear: both;

			>span {
				text-align: center;
				display: inline-block;
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}

.fwbpan {
	h3 {
		color: #fff;
		font-size: 38px;
		text-align: center;
		margin-bottom: 22px;
	}

	.fieldable-panels-pane {
		margin-bottom: 20px;

		@include breakpoint($md) {
			margin-bottom: 0;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;

		li {
			display: inline;
			text-align: center;

			a.btn {
				text-align: center;
				background-color: #fff;
				border: 4px solid #d5d9d9;
				padding: 9px 20px;
				margin: 7px;
				min-width: 215px;

				&:hover,
				&:focus {
					color: $wcm-bright-orange;
					border: 4px solid $wcm-bright-orange;
				}
			}
		}
	}
}

.upper-right>.panel-pane {
	margin-top: 27px;
	float: left;
	width: 100%;
}

.awardsinfo {
	h2 {
		text-align: center;
		color: #7a7b7c;
		font-size: 24px;
		font-family: "Open Sans", sans-serrif;
		font-weight: bold;
		margin-top: 26px;
	}

	p.tots {
		text-align: center;
		font-weight: bold;
		margin-bottom: 20px;
	}

	ul.legend {
		padding: 0;
		margin: 0 auto;
		display: block;
		list-style: none;
		max-width: 230px;

		@include breakpoint($md) {
			margin: 0;
		}

		em {
			font-style: normal;
		}
	}

	.numberval {
		font-weight: 600;
		min-width: 53px;
		text-align: right;
		display: inline-block;
	}

	.pilota {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: cornflowerblue;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}

	.plana {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: olivedrab;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}

	.communitya {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: orange;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}

	.novela {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: tomato;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}

	.nta {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: crimson;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}

	.rdnovela {
		&:after {
			content: " ";
			width: 16px;
			height: 16px;
			display: inline-block;
			background-color: purple;
			margin: 0 5px;
			top: 3px;
			position: relative;
		}
	}
}

/* Info Graphic SASS */
@keyframes bake-pie {
	from {
		transform: rotate(0deg) translate3d(0, 0, 0);
	}
}


.pieID,
.pieIDed {
	display: inline-block;
	vertical-align: top;

}

.pie {
	height: 152px;
	width: 152px;
	position: relative;
	margin: 0 auto 30px;
	display: block;

	@include breakpoint($md) {
		margin: 0 0 30px 0;
		float: right;
	}
}

.pie::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	width: 76px;
	height: 76px;
	background: #EEE;
	border-radius: 50%;
	top: 38px;
	left: 38px;
}

.slice,
.sliceEd {
	position: absolute;
	width: 152px;
	height: 152px;
	clip: rect(0px, 152px, 152px, 76px);
	animation: bake-pie 1s;
}

.slice span,
.sliceEd span {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	background-color: black;
	width: 152px;
	height: 152px;
	border-radius: 50%;
	clip: rect(0px, 152px, 152px, 76px);
}

ul.pieIDed.legend,
ul.pieID.legend {
	margin-bottom: 20px;
}


/* Info Grpahic SASS end */


.information-sidebar {

	.btn--wcm {
		margin-bottom: 20px;
		padding: 10px 20px;

		&:before {
			line-height: 2.5;
		}
	}

	.how_dropdown {
		margin-bottom: 32px;

		h3 {
			color: #000;
			font-size: 14px;
			text-align: center;
			margin-bottom: 5px;

			label {
				margin-bottom: 0;
			}
		}

		.select2-container {
			width: 100% !important;
			margin-bottom: 32px;
		}
	}

	.socrow {
		width: 100%;
		float: left;

		.cpsoc {
			display: table;
			margin: 0 auto 20px;
			width: 125px;
		}
	}
}

a.cpsoc.external-link {
	&:after {
		display: none;
	}
}

/* Leadership Pane */
.pane-profiles-panel-pane-3,
.pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5,
.pane-profiles-panel-pane-6 {


	.views-row {
		display: inline-block;
		clear: both;
		width: 100%;
		border: 1px solid #f5f5f5;
		padding: 10px 30px;
		margin-bottom: 20px;

		.views-label {
			display: inline;
		}

		.field-content {
			display: inline;
		}

		.views-field {
			margin-left: 20px;

			@include breakpoint($md) {
				margin-left: 0;
			}
		}

		.views-field-field-profile-image {
			display: block;
			float: left;
			margin-right: 35px;
			margin-left: 0;
		}

		h3.display-name {
			color: #4b4b4b;
			font-size: 15px;
		}

		.views-field-field-biographical-info {
			.field-content {
				max-height: 75px;
			}
		}

		a.exmore {
			&:hover {
				text-decoration: none;

				span {
					text-decoration: none;
				}
			}

			&:before {
				content: "...";
				color: #000000;
				display: inline-block;
				width: 100%;
			}

			span {
				font-size: 30px;
				font-weight: 600;
				position: relative;
				top: 4px;
				text-decoration: none;
			}
		}

		a.exmore.cls {

			//margin-left: 230px;
			&:before {
				content: " ";
				display: none;
			}
		}
	}
}

.views-field-field-biographical-info .field-content+[data-readmore-toggle],
.views-field-field-biographical-info .field-content[data-readmore] {
	width: auto !important;
}

.pane-profiles-panel-pane-4 {

	.views-row {
		.views-field {
			margin-left: 0;

			a {
				border-bottom: none;
			}
		}
	}

	div[class*='col-sm'] {
		padding-left: 0;
		padding-right: 0;
	}

	.col-sm-9 {
		@include breakpoint($sm) {
			padding-left: 35px !important;
		}
	}
}

.pane-profiles-panel-pane-6 {
	.views-row {
		.views-field {
			margin-left: 0;

			a {
				border-bottom: none;
			}
		}
	}

	div[class*='col-sm'] {
		padding-left: 0;
		padding-right: 0;
	}

	.col-sm-3 {
		&.no-profile-image {
			display: none;
		}
	}

	.col-sm-3.no-profile-image+.col-sm-9 {
		@include breakpoint($sm) {
			padding-left: 0px !important;
			width: 100%;
		}
	}

	.col-sm-9 {
		@include breakpoint($sm) {
			padding-left: 35px !important;
		}
	}
}

.pane-profiles-panel-pane-5 .views-row {
	border-width: 0 0 1px;
	padding: 0 0 30px;
}

.pane-profiles-panel-pane-5 .views-row:last-child {
	border: none;
}

/**/

/*News page*/

.calendar-link-container {
	border-width: 3px 0;
	border-color: #ddd;
	border-style: solid;
	padding: 20px 0;

	.calendar-link-right {
		padding-left: 3rem;
		border-left: 3px solid #ddd;

		.calendar-link-title {
			font-family: '1898Sans-Bold';
			font-size: 22px;

			&:after {
				content: '\e80d';
				color: $wcm-bright-orange;
				;
				padding-left: 0;
				font-size: 60%;
				vertical-align: top;
				@include fontello();
				text-decoration: none;
				line-height: 2.5;
			}
		}

		.calendar-link-body {
			font-size: 16px;
			color: #7a7a7a;
		}
	}

	.calendar-link-left {
		padding-top: 0;
		padding: 0 18px;

		svg {
			width: 60px;
			height: 60px;
			display: block;
			fill: $wcm-bright-orange;
		}
	}
}

.pane-news-panel-pane-6 {
	.views-row {
		float: left;
		width: 100%;
		border-bottom: 1px solid #eeeff0;
		padding: 25px 0;

		&:first-child {
			padding-top: 5px;

		}
	}

	h3.teaser-title {
		font-size: 18px;
		line-height: 1.5;

		@include breakpoint($sm) {
			margin-top: 25px;
		}

		a {
			&:after {
				line-height: 2.5;
			}
		}
	}

	p.post-date {
		text-transform: uppercase;
		color: #68696a;
		font-size: 12px;
		font-weight: bold;
	}

	.teaser-text p {
		font-size: 16px;
		line-height: 25px;
	}

	.teaser-text a {
		&:after {
			content: '\e80d';
			color: #e87722;
			padding-left: 10px;
			font-size: 60%;
			vertical-align: top;
			font-family: "fontello";
			font-style: normal;
			font-weight: normal;
			speak: none;
			display: inline-block;
			text-decoration: inherit;
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: 1em;
			text-decoration: none;
			line-height: 3;
		}
	}

	.teaser-image {
		@include breakpoint($sm) {
			float: right;
			//margin-bottom: 43px;
			margin-top: 25px;
		}
	}
}

.post-date {
	text-transform: uppercase;
	color: #68696a;
	font-size: 12px;
	font-weight: bold;
}


.pane-node-webform {
	input.btn {
		border-radius: 8px;
		font-size: 13px;
		padding: 4px 32px;
	}
}

.pane-ctsc-demogstats {
	background-color: #f7f7f7;

	h3 {
		font-size: 20px;
		color: $wcm-red;
		text-align: center;
		margin-top: 40px;
		float: left;
		width: 100%;
	}

	.pie {
		margin-bottom: 40px;
	}
}

.pane-research-resources-panel-pane-1 {
	h3.pane-title {
		margin: 30px 0 5px;
	}
}

.vrt {
	display: block;
	background-color: #f6f7f8;
	width: 100%;
	min-height: 312px;
	margin: 60px 0 30px;

	span.logo {
		font-size: 52px;
		text-align: center;
		float: left;
		width: 100%;
		margin-top: 62px;

		svg {
			max-width: 96px;
		}
	}

	span.viewl {
		position: absolute;
		bottom: 45px;
		right: 32px;
		line-height: 24px;

		svg {
			//color: ;
			width: 24px;
			height: 24px;
			fill: $wcm-bright-orange;
			top: 7px;
			position: relative;
			margin-left: 5px;
		}
	}

	h3 {
		text-align: center;
	}
}

.appedbtns {
	float: left;
	width: 100%;

	.btn {
		padding: 10px 20px;
		margin-bottom: 20px;

		&:before {
			line-height: 2.5;
		}
	}
}


.pane-programs-panel-pane-1 {
	h3.pane-title {
		margin: 15px 0 10px;
	}

	a.views-more-link {
		display: inline-block;
		color: #fff;
		background-color: $wcm-dark-orange;
		padding: 4px 30px;
		border-radius: 8px;
		margin: 15px 0 20px;
	}
}

.pane-wcmc-second-level-nav {
	float: left;
	width: 100%;
}

.inquiries {
	h3 {
		margin: 0;
		line-height: 32px;

		&:before {
			background-position: -600px 0px;
			content: '';
			display: block;
			width: 32px;
			height: 32px;
			float: left;
			margin-right: 10px;
			background-repeat: no-repeat;
			background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
			background-size: 632px 152px;
		}
	}

	a {
		color: $wcm-bright-orange;
	}
}

.pane-node-field-prog-related-links {
	>div {
		list-style: outside;

		a {
			width: 100%;
			float: left;
			color: $wcm-bright-orange;
		}
	}
}

.applbtn {
	float: left;

	a {
		padding: 10px 20px;
		color: $wcm-bright-orange;
		position: relative;
		display: block;
		width: 220px;
		margin: 20px auto;
		border-radius: 20px;
		font-size: 13px;
		background-color: transparent;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		border: 4px solid $wcm-bright-orange;
		white-space: nowrap;
	}
}

.contact-info .site-name {
	margin: 0 12px;
}

.pane-courses-panel-pane-1 {
	float: left;
	width: 100%;

	.field-label {
		font-weight: 700;
		display: inline;
	}

	span.element__toggle {
		display: block;
	}

	p {
		border-top: 1px solid #f5f6f7;
		margin: 10px 0 20px;
		padding-top: 10px;
	}
}

.coursetxt {
	background-color: #f6f7f8;
	padding: 0 25px 25px;
	display: inline-block;
	width: 100%;
	clear: both;

	.taxonomy-term-description {
		margin: 0;

		p {
			margin: 2px 0 12.5px;
			padding: 0;
		}
	}
}

.pane-views-exp-courses-panel-pane-1 {
	background-color: #f6f7f8;
	padding: 0 25px 0;
	float: left;
	width: 100%;

	label {
		font-size: 14px;
		font-weight: 600;
		margin-top: 5px;
		margin-bottom: 10px;
	}

	select.form-select {
		max-width: none;
	}
}

.pane-events-panel-pane-3 .view-display-id-panel_pane_1 {
	float: left;
	width: 100%;
}

.element--show-hide {
	&:last-child {
		border-bottom: 1px solid #ddd;
		margin-bottom: 20px;
	}
}

.ctaban {
	display: block;
	width: 100%;
}

.comorp {
	background: url(../img/drpat.jpg) 0 0 no-repeat;
	background-size: 100% auto;
	background-color: #f6f7f8;
	padding: 300px 30px 30px;

	h3 {
		a {
			font-size: 16px;
			color: $wcm-dark-orange;

			&:after {
				line-height: 2.5;
			}
		}
	}

	h3.pane-title {
		margin-top: 0;
		margin-bottom: 14px;

		a {
			&:after {
				line-height: 2.5;
			}
		}
	}

	@include breakpoint($sm) {
		padding-top: 500px;
	}

	@include breakpoint($md) {
		padding-top: 250px;
	}

	@include breakpoint($lg) {
		padding-top: 300px;
	}
}

.reccta {
	background: url(../img/comrec.jpg) 0 0 no-repeat;
	background-size: 100% auto;
	background-color: #f5f6f7;
	padding: 300px 30px 30px;
	min-height: 147px;
	margin-bottom: 24px;

	h3 {

		margin-top: 0;
		font-size: 18px;
		margin-bottom: 14px;
	}

	p {
		a {
			font-size: 16px;
			color: $wcm-dark-orange;
			font-family: "1898Sans-Bold", sans-serif;
			font-weight: normal;

			&:after {
				content: '\e80d';
				color: #e87722;
				padding-left: 10px;
				font-size: 60%;
				vertical-align: top;
				font-family: "fontello";
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				line-height: 1em;
				text-decoration: none;
				line-height: 2.5;
			}
		}
	}

	@include breakpoint($sm) {
		padding-top: 500px;
	}

	@include breakpoint($md) {
		padding-top: 250px;
	}

	@include breakpoint($lg) {
		padding-top: 300px;
	}
}

.pane-research-studies-panel-pane-1 {
	&:last-child {
		border-bottom: 1px solid #ddd;
	}

	h3.pane-title {
		margin-bottom: 10px;
	}

	ul {
		margin-left: 25px;
	}
}

.npl {
	@include breakpoint($md) {
		padding-left: 0;
	}
}

.npr {
	@include breakpoint($md) {
		padding-right: 0;
	}
}

.h2hspon {
	float: left;
	width: 100%;
	margin-top: 18px;

	h3 {
		color: #000;
		font-size: 14px;
		text-align: center;
	}

	p {
		margin: 0 0 10px;
	}

	a {
		color: $wcm-dark-orange;

		&:after {
			color: $wcm-bright-orange;
		}
	}
}

/* Summer Fellows Pane */

.pane-awardee-panel-pane-1,
.pane-awardee-panel-pane-2,
.pane-awardee-panel-pane-3,
.field-collection-container {
	h3 {
		margin-bottom: 10px;
		text-align: left;
	}

	table {
		margin: 0 0 16px 0;
		border-top-color: #f5f6f7;

		thead {
			border-color: #f5f6f7;

			tr {
				border-color: #f5f6f7;

				th {
					background-color: #f5f6f7;
					border-color: #f5f6f7;
				}
			}
		}

		tbody {
			border-color: #f5f6f7;

			tr {
				background-color: #fff;

				&:nth-child(odd)>td {
					background-color: #fff;
				}

				td {
					border-color: #f5f6f7;
				}
			}
		}
	}

	.views-field-field-awardee-institution {
		min-width: 116px;
	}

	td.picopi {
		min-width: 60px;
	}

	td.views-field-field-related-co-pis {
		display: none;
	}
}

.pane-node-body {
	table {
		margin: 10px 0 16px 0;
		border-top-color: #f5f6f7;

		thead {
			border-color: #f5f6f7;

			tr {
				border-color: #f5f6f7;

				th {
					background-color: #f5f6f7;
					border-color: #f5f6f7;
				}
			}
		}

		tr {
			border-color: #f5f6f7;

			th {
				background-color: #f5f6f7;
				border-color: #f5f6f7;
				padding: 8px;
			}
		}

		tbody {
			border-color: #f5f6f7;

			tr {
				background-color: #fff;

				&:nth-child(odd)>td {
					background-color: #fff;
				}

				td {
					border-color: #f5f6f7;
					border: 1px solid #f5f6f7;
					padding: 8px;
				}
			}
		}
	}
}


/* Summer Fellows Pane END */

/* slider style */

.slide-text {
	background: #fff;
	opacity: .95;

	h2 {
		color: #555;

		a {
			color: #555;
		}
	}

	p {
		color: #555;
	}
}

.thumbnail-controller {
	.pager {
		margin: 14px 0;
		text-transform: none;
	}
}

.findac {
	input[type=text] {
		//float: left;
		min-width: 66%;
		border: 3px solid #d8d8d8;
		border-right: none;
		border-radius: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		height: 43px;
		line-height: 43px;
		padding: 0 20px;

		@include breakpoint($sm) {
			min-width: 80%;
		}
	}

	p {
		margin: 18px 0 5px;
	}

	span.btmtxt {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}

	a.btn {
		position: relative;
		top: -2px;
		left: -4px;
		background-color: $wcm-bright-orange;
		padding: 11px 20px 7px 48px;
		margin: 0;
		border: none;
		border-radius: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		svg {
			position: absolute;
			top: 12px;
			left: 12px;
			color: #fff;
			fill: #fff;
			width: 24px;
			height: 24px;
		}

		&:hover {
			background-color: $wcm-dark-orange;
		}
	}
}

iframe {
	border: 0;
}

.pane-wcm-postdocs-horcta {
	border-top: solid 13px #f7f7f7;
	display: inline-block;
	width: 100%;
	margin-top: 3px;
	padding-top: 25px;
}

.horctas {
	.wcm-cta {
		float: left;
		width: 33.33%;

		a {
			float: left;
			width: 50%;
		}
	}

	.how_dropdown {
		width: 33.33%;

		h3 {
			color: #000;
			font-size: 14px;
			text-align: center;
		}

		.select2-container {
			display: block;
			margin: 0 auto !important;
		}
	}

	.socrow {
		width: 33.33%;
		margin-top: 50px;
	}
}

.social-share__print a:before {
	content: '\e814';
}

// .vidpane{
// 	h3{
// 		margin-bottom: -20px;
// 	}
// }



.pane-specialized-cores-panel-pane-1 {
	.views-row {
		border-bottom: 1px solid #d8d8d8;
		margin-bottom: 15px;
		padding-bottom: 5px;
	}

	.views-field-title {
		font-size: 16px;

	}
}

#related-content-sidebar h3.pane-title {
	font-family: "Open Sans", sans-serrif;
}

/* =FAQ
----------------------------------------------------------*/

.view-faq .view-content>h3 {
	font-size: 18px;
	color: $wcm-med-gray;
}

/* =Footer
----------------------------------------------------------*/

.front footer {
	padding-top: 0;
}

footer {
	//WEBCTSC-189
	padding-top: 40px;
}

.footer-address__address {
	a {
		border-bottom: 1px dotted;
	}
}

.footer-nav {
	margin: 0 0 20px;
}

.edutraincar {
	.slide-text {
		display: none;
	}
}

.lnkscta {
	h3 {
		font-size: 16px;
		color: #000;
		padding-left: 38px;
	}
}

.callout-image {
	background-position: center, center;
	background-repeat: no-repeat;
	background-size: 100% auto;
	display: block;
	width: 100%;
	min-height: 320px;
	margin-top: 50px;
	margin-bottom: 30px;
}

.callout-btns {
	border-left: 1px solid $wcm-dark-orange;
	padding-left: 38px;
	list-style: none;
	float: left;

	li {
		float: left;
		clear: both;

		&:hover {
			position: relative;
			left: -22px;

			&:before {
				content: '\e80d';
				position: relative;
				left: -26px;
				color: $wcm-dark-orange;
				padding-left: 10px;
				font-size: 100%;
				vertical-align: top;
				font-family: "fontello";
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				top: 5px;
			}

			a {
				border-bottom: 1px solid $wcm-bright-orange;
				color: $wcm-bright-orange;
			}
		}
	}

	li a {
		border-bottom: 1px solid #fff;
		line-height: 35px;
		font-size: 16px;
		color: $wcm-dark-orange;
		font-family: "Open Sans", sans-serif;
		font-weight: bold;
		text-decoration: none;
		//
	}
}

.welcome {
	padding-bottom: 6px;

	.col-md-3 {
		padding-left: 0;
	}

	padding-top: 10px;

	h2 {
		margin-bottom: 23px;
	}

	p {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 30px;
	}

	p.caption-text {
		font-size: 15px;
		line-height: 24px;
		font-style: italic;
	}
}

.fwbpan {
	background-color: #f9fafc;

	h2 {
		margin: 30px 0 22px;
	}

	.pane-consortium-panel-pane-1 {
		margin-bottom: 13px;
	}

	.btn {
		margin-bottom: 23px;
	}
}

/* RFA */

.rfacta {
	padding: 30px;
	background-color: #f6f7f8;

	h3 {
		text-align: center;
		padding-bottom: 15px;
	}

	p {
		line-height: 26px;
		text-align: center;
	}

	a.btn {
		width: 145px;
		margin: 5px auto 0;
		float: none;
		display: block;
		padding: 4px 15px;
	}
}

.view-id-rfa>div>h3 {
	font-size: 30px;
}

.pane-rfa-panel-pane-1 {
	.views-row {
		border-bottom: 1px solid #ececee;
	}

	.views-field-field-deadline-date {
		text-transform: uppercase;
		color: #646566;
		display: inline-block;
		font-weight: bold;
		margin-bottom: 15px;

		.field-content {
			display: inline;
		}
	}
}

.pane-node-field-deadline-date {
	text-transform: uppercase;
	color: #646566;
	display: inline-block;
	font-weight: bold;
	margin-bottom: 15px;

	.field-label,
	.date-display-single {
		display: inline;
	}
}

.pane-node-field-application-link {
	a {
		float: left;
		font-size: 13px;
		border: 3px solid $wcm-dark-orange;
		border-radius: 18px;
		padding: 9px 25px;
		margin-top: 20px;
	}
}

.view-id-events,
.view-news {
	h3 a {
		&:hover {
			color: $wcm-bright-orange;
		}
	}
}

/*eNews*/

.pane-enews-panel-pane-1 {
	.view-content {
		margin-top: 20px;
		display: inline-block;
		width: 100%;

		h3 {
			margin-top: 20px;
			font-size: 18px;
		}

		.views-field-created {
			font-weight: bold;
			font-size: 14px;
			color: #777879;
			text-transform: uppercase;
			margin-top: 1px;
		}

		.views-row {
			border-bottom: 1px solid #eeeff0;
			padding-bottom: 17px;
		}
	}
}

.node-ctep-news {
	h3 {
		font-size: 30px;
	}

	.pane-node-created {
		font-size: 14px;
		color: #000;
		text-transform: uppercase;
		display: inline;
		font-weight: bold;
		margin-bottom: 15px;
		display: inline-block;

		h3 {
			font-size: 14px;
			color: #000;
			text-transform: uppercase;
			display: inline;
		}
	}
}

.sfatab {
	td {
		min-width: 60px;
		padding: 5px;
	}
}
