@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.RYPP {
  /* Youtube Player With Playlist */
  width: 100%;
  position: relative;
  padding-right: 250px;
  background: #1a1a1a;
  overflow: hidden;
  font: 400 14px/120% Arial, Helvetica, sans-serif;
  color: #999; }
  .RYPP .RYPP-video {
    width: 100%;
    padding-top: 56.25%;
    float: left;
    position: relative; }
    .RYPP .RYPP-video > iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
  .RYPP.r16-9 .RYPP-video {
    padding-top: 56.25%; }
  .RYPP.r4-3 .RYPP-video {
    padding-top: 75%; }
  .RYPP.r2-1 .RYPP-video {
    padding-top: 50%; }
  .RYPP .RYPP-items {
    width: 100%;
    position: absolute;
    left: 0;
    top: 64px;
    right: 0;
    bottom: 0;
    overflow-y: auto; }
  .RYPP .RYPP-playlist {
    width: 250px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #222; }
    .RYPP .RYPP-playlist a {
      color: #999; }
      .RYPP .RYPP-playlist a:hover {
        color: #fff; }
    .RYPP .RYPP-playlist > header {
      width: 100%;
      height: 64px;
      padding: 16px 0 0 8px;
      background: #222;
      border-left: 8px solid #222;
      border-right: 8px solid #222;
      border-bottom: 1px solid #3a3a3a;
      z-index: 2; }
      .RYPP .RYPP-playlist > header ._h1 {
        margin: 0 0 4px 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        color: #fff; }
      .RYPP .RYPP-playlist > header p {
        margin: 0;
        padding: 0; }
    .RYPP .RYPP-playlist ol {
      padding: 0 0 16px 0;
      margin: 0;
      list-style: none;
      counter-reset: item; }
      .RYPP .RYPP-playlist ol li {
        position: relative;
        vertical-align: middle;
        overflow: hidden;
        padding: 12px 0 12px 12px; }
        .RYPP .RYPP-playlist ol li > img {
          width: 18%;
          display: inline-block;
          vertical-align: middle;
          border: 2px solid transparent; }
        .RYPP .RYPP-playlist ol li > p {
          width: 76%;
          margin: 0;
          padding: 0 16px 0 0;
          float: right;
          display: inline-block;
          vertical-align: middle;
          text-align: left;
          color: #cacaca; }
          .RYPP .RYPP-playlist ol li > p > small {
            margin: 0;
            padding: 0;
            font-size: 80%; }
        .RYPP .RYPP-playlist ol li:hover {
          background-color: #3a3a3a;
          cursor: pointer; }
        .RYPP .RYPP-playlist ol li.selected {
          background-color: #3a3a3a;
          cursor: pointer; }
          .RYPP .RYPP-playlist ol li.selected > p {
            color: #fff; }
          .RYPP .RYPP-playlist ol li.selected > img {
            border: 2px solid #c03636; }
          .RYPP .RYPP-playlist ol li.selected:before {
            content: '▶';
            font-size: 10px;
            color: #c03636; }
        .RYPP .RYPP-playlist ol li:before {
          counter-increment: item;
          content: counter(item);
          font-size: 80%; }

.RYPP-items::-webkit-scrollbar {
  width: 6px;
  background: #3a3a3a; }

.RYPP-items::-webkit-scrollbar-thumb {
  background: #999; }

@media only screen and (max-width: 639px) {
  .RYPP {
    padding-right: 0; }
    .RYPP .RYPP-playlist {
      width: 100%;
      position: static;
      float: left; }
      .RYPP .RYPP-playlist .RYPP-items {
        position: static;
        max-height: 350px;
        overflow: auto; } }

.view .date-nav-wrapper .date-prev {
  right: 119px; }
  @media screen and (max-width: 767px) {
    .view .date-nav-wrapper .date-prev {
      right: auto; } }

.view .date-nav-wrapper .date-heading h3 {
  text-align: left;
  padding-top: 8px; }

.view-id-Events_Cal {
  margin-top: 20px; }

.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next {
  background-color: #fff;
  border-radius: 8px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next {
      position: absolute; } }
  .view .date-nav-wrapper .date-prev a, .view .date-nav-wrapper .date-next a {
    background-color: #cf4520;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0 35px;
    height: 30px;
    line-height: 30px;
    margin: 0; }

.calendar-calendar th.days {
  background-color: #f0f1f2; }

.calendar-calendar .views-field-field-date-and-time {
  float: left;
  padding-right: 5px !important; }

.calendar-calendar .month-view .full td.single-day div.monthview {
  border-radius: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px; }
  .calendar-calendar .month-view .full td.single-day div.monthview div.contents {
    padding-bottom: 9px; }

.calendar-calendar .month-view .full tr td.multi-day, .calendar-calendar .month-view .full tr td.single-day {
  border-radius: 0px;
  padding: 0;
  margin: 0; }

.calendar-calendar .views-field-title {
  float: left; }

.calendar-calendar .month-view div.content {
  margin-bottom: 9px; }

.lf-ghost {
  height: auto !important; }

div.modal-forms-modal-content .popups-close {
  background: url(../images/close.png) 0 0 no-repeat; }
  div.modal-forms-modal-content .popups-close:hover {
    background: url(../images/close.png) 0 0 no-repeat; }

.close {
  opacity: 1; }
  .close:hover {
    opacity: 1; }

.view-content .calendar-calendar {
  margin-bottom: 20px; }

#reserach-home-upper-left {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px; }
  #reserach-home-upper-left .view-teaser {
    width: 100%; }
    @media screen and (min-width: 768px) {
      #reserach-home-upper-left .view-teaser {
        width: 33.3333%;
        float: left;
        padding-left: 5px;
        padding-right: 5px; }
        #reserach-home-upper-left .view-teaser:first-child {
          padding-left: 0;
          padding-right: 10px; }
        #reserach-home-upper-left .view-teaser:last-child {
          padding-right: 0;
          padding-left: 10px; } }

.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_1 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_5 .view-item .calendar,
.calendar-calendar .week-view div.single-day .i_0.md_0 .view-item .calendar {
  width: 100% !important;
  border: 0;
  border-radius: 0;
  margin-left: 0; }

.calendar-calendar .day-view .full div.calendar-agenda-hour, .calendar-calendar .day-view .full div.calendar-agenda-items {
  height: 74px; }

.calendar-calendar div.single-day .d_4 .view-item .calendar {
  height: 73px; }

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour {
  height: 37px; }

.calendar-calendar .week-view div.single-day .half-hour, .calendar-calendar .day-view div.single-day .half-hour {
  border-bottom: none; }

.calendar-calendar .week-view .full div.item-wrapper, .calendar-calendar .day-view .full div.item-wrapper {
  margin-top: -37px; }

/* =Week View Adjustments - WEBCTSC-142
----------------------------------------------------------*/
.calendar-calendar .week-view .full div.calendar-agenda-hour {
  height: 150px; }

.calendar-calendar div.single-day .d_4 .view-item .calendar.weekview {
  height: 150px; }

.calendar-calendar .week-view .full div.calendar-agenda-items {
  height: 150px; }

.calendar-calendar .week-view .full div.single-day div.weekview {
  overflow: visible; }

.calendar-calendar .week-view .full td .inner div.calendar.weekview div {
  background: #ffc; }

/*
----------------------------------------------------------*/
.calendar-calendar div.single-day .o_0 .view-item {
  top: 0px; }

.calendar-calendar div.single-day .o_1 .view-item {
  top: 18px; }

.calendar-calendar div.single-day .o_2 .view-item {
  top: 37px; }

.calendar-calendar div.single-day .o_3 .view-item {
  top: 55px; }

.calendar-calendar div.single-day .o_4 .view-item {
  top: 73px; }

.calendar-calendar div.single-day .d_1 .view-item .calendar {
  height: 18px; }

.calendar-calendar div.single-day .d_2 .view-item .calendar {
  height: 37px; }

.calendar-calendar div.single-day .d_3 .view-item .calendar {
  height: 55px; }

.calendar-calendar div.single-day .d_4 .view-item .calendar {
  height: 74px; }

.calendar-calendar div.single-day .d_5 .view-item .calendar {
  height: 92px; }

.calendar-calendar div.single-day .d_6 .view-item .calendar {
  height: 111px; }

.calendar-calendar div.single-day .d_7 .view-item .calendar {
  height: 129px; }

.calendar-calendar div.single-day .d_8 .view-item .calendar {
  height: 148px; }

.calendar-calendar div.single-day .d_9 .view-item .calendar {
  height: 166px; }

.calendar-calendar div.single-day .d_10 .view-item .calendar {
  height: 185px; }

.calendar-calendar div.single-day .d_11 .view-item .calendar {
  height: 203px; }

.calendar-calendar div.single-day .d_12 .view-item .calendar {
  height: 222px; }

.calendar-calendar div.single-day .d_13 .view-item .calendar {
  height: 240px; }

.calendar-calendar div.single-day .d_14 .view-item .calendar {
  height: 259px; }

.calendar-calendar div.single-day .d_15 .view-item .calendar {
  height: 277px; }

.calendar-calendar div.single-day .d_16 .view-item .calendar {
  height: 296px; }

.calendar-calendar div.single-day .d_17 .view-item .calendar {
  height: 314px; }

.calendar-calendar div.single-day .d_18 .view-item .calendar {
  height: 333px; }

.calendar-calendar div.single-day .d_19 .view-item .calendar {
  height: 351px; }

.calendar-calendar div.single-day .d_20 .view-item .calendar {
  height: 370px; }

.calendar-calendar div.single-day .d_21 .view-item .calendar {
  height: 388px; }

.calendar-calendar div.single-day .d_22 .view-item .calendar {
  height: 407px; }

.calendar-calendar div.single-day .d_23 .view-item .calendar {
  height: 425px; }

.calendar-calendar div.single-day .d_24 .view-item .calendar {
  height: 444px; }

.calendar-calendar div.single-day .d_25 .view-item .calendar {
  height: 462px; }

.calendar-calendar div.single-day .d_26 .view-item .calendar {
  height: 481px; }

.calendar-calendar div.single-day .d_27 .view-item .calendar {
  height: 499px; }

.calendar-calendar div.single-day .d_28 .view-item .calendar {
  height: 518px; }

.calendar-calendar div.single-day .d_29 .view-item .calendar {
  height: 536px; }

.calendar-calendar div.single-day .d_30 .view-item .calendar {
  height: 555px; }

.calendar-calendar div.single-day .d_31 .view-item .calendar {
  height: 573px; }

.calendar-calendar div.single-day .d_32 .view-item .calendar {
  height: 592px; }

.calendar-calendar div.single-day .d_33 .view-item .calendar {
  height: 610px; }

.calendar-calendar div.single-day .d_34 .view-item .calendar {
  height: 626px; }

.calendar-calendar div.single-day .d_35 .view-item .calendar {
  height: 647px; }

.calendar-calendar div.single-day .d_36 .view-item .calendar {
  height: 666px; }

.calendar-calendar div.single-day .d_37 .view-item .calendar {
  height: 684px; }

.calendar-calendar div.single-day .d_38 .view-item .calendar {
  height: 700px; }

.calendar-calendar div.single-day .d_39 .view-item .calendar {
  height: 721px; }

.calendar-calendar div.single-day .d_40 .view-item .calendar {
  height: 740px; }

.calendar-calendar div.single-day .d_41 .view-item .calendar {
  height: 490px; }

.calendar-calendar div.single-day .d_42 .view-item .calendar {
  height: 502px; }

.calendar-calendar div.single-day .d_43 .view-item .calendar {
  height: 514px; }

.calendar-calendar div.single-day .d_44 .view-item .calendar {
  height: 526px; }

.calendar-calendar div.single-day .d_45 .view-item .calendar {
  height: 538px; }

.calendar-calendar div.single-day .d_46 .view-item .calendar {
  height: 550px; }

.calendar-calendar div.single-day .d_47 .view-item .calendar {
  height: 562px; }

.calendar-calendar div.single-day .d_48 .view-item .calendar {
  height: 574px; }

.calendar-calendar div.single-day .d_49 .view-item .calendar {
  height: 586px; }

.calendar-calendar div.single-day .d_50 .view-item .calendar {
  height: 598px; }

.calendar-calendar div.single-day .d_51 .view-item .calendar {
  height: 610px; }

.calendar-calendar div.single-day .d_52 .view-item .calendar {
  height: 622px; }

.calendar-calendar div.single-day .d_53 .view-item .calendar {
  height: 634px; }

.calendar-calendar div.single-day .d_54 .view-item .calendar {
  height: 646px; }

.calendar-calendar div.single-day .d_55 .view-item .calendar {
  height: 658px; }

.calendar-calendar div.single-day .d_56 .view-item .calendar {
  height: 670px; }

.calendar-calendar div.single-day .d_57 .view-item .calendar {
  height: 682px; }

.calendar-calendar div.single-day .d_58 .view-item .calendar {
  height: 694px; }

.calendar-calendar div.single-day .d_59 .view-item .calendar {
  height: 706px; }

.calendar-calendar div.single-day .d_60 .view-item .calendar {
  height: 718px; }

.calendar-calendar div.single-day .d_61 .view-item .calendar {
  height: 730px; }

.calendar-calendar div.single-day .d_62 .view-item .calendar {
  height: 742px; }

.calendar-calendar div.single-day .d_63 .view-item .calendar {
  height: 754px; }

.calendar-calendar div.single-day .d_64 .view-item .calendar {
  height: 766px; }

.calendar-calendar div.single-day .d_65 .view-item .calendar {
  height: 778px; }

.calendar-calendar div.single-day .d_66 .view-item .calendar {
  height: 790px; }

.calendar-calendar div.single-day .d_67 .view-item .calendar {
  height: 802px; }

.calendar-calendar div.single-day .d_68 .view-item .calendar {
  height: 814px; }

.calendar-calendar div.single-day .d_69 .view-item .calendar {
  height: 826px; }

.calendar-calendar div.single-day .d_70 .view-item .calendar {
  height: 838px; }

.calendar-calendar div.single-day .d_71 .view-item .calendar {
  height: 850px; }

.calendar-calendar div.single-day .d_72 .view-item .calendar {
  height: 862px; }

.calendar-calendar div.single-day .d_73 .view-item .calendar {
  height: 874px; }

.calendar-calendar div.single-day .d_74 .view-item .calendar {
  height: 886px; }

.calendar-calendar div.single-day .d_75 .view-item .calendar {
  height: 898px; }

.calendar-calendar div.single-day .d_76 .view-item .calendar {
  height: 910px; }

.calendar-calendar div.single-day .d_77 .view-item .calendar {
  height: 922px; }

.calendar-calendar div.single-day .d_78 .view-item .calendar {
  height: 934px; }

.calendar-calendar div.single-day .d_79 .view-item .calendar {
  height: 946px; }

.calendar-calendar div.single-day .d_80 .view-item .calendar {
  height: 958px; }

.calendar-calendar div.single-day .d_81 .view-item .calendar {
  height: 970px; }

.calendar-calendar div.single-day .d_82 .view-item .calendar {
  height: 982px; }

.calendar-calendar div.single-day .d_83 .view-item .calendar {
  height: 994px; }

.calendar-calendar div.single-day .d_84 .view-item .calendar {
  height: 1006px; }

.calendar-calendar div.single-day .d_85 .view-item .calendar {
  height: 1018px; }

.calendar-calendar div.single-day .d_86 .view-item .calendar {
  height: 1030px; }

.calendar-calendar div.single-day .d_87 .view-item .calendar {
  height: 1042px; }

.calendar-calendar div.single-day .d_88 .view-item .calendar {
  height: 1054px; }

.calendar-calendar div.single-day .d_89 .view-item .calendar {
  height: 1066px; }

.calendar-calendar div.single-day .d_90 .view-item .calendar {
  height: 1078px; }

.calendar-calendar div.single-day .d_91 .view-item .calendar {
  height: 1090px; }

.calendar-calendar div.single-day .d_92 .view-item .calendar {
  height: 1102px; }

.calendar-calendar div.single-day .d_93 .view-item .calendar {
  height: 1114px; }

.calendar-calendar div.single-day .d_94 .view-item .calendar {
  height: 1128px; }

.calendar-calendar div.single-day .d_95 .view-item .calendar {
  height: 1140px; }

.calendar-calendar div.single-day .d_96 .view-item .calendar {
  height: 1152px; }

.evntlink {
  line-height: 2.0;
  margin: 25px auto 0; }

.caltabs {
  margin-top: 15px; }

.views-widget-filter-field_event_category_tid .select2-container {
  min-width: 210px; }

.views-exposed-form .views-widget-filter-field_event_location_tid_selective {
  padding: 0 0 10px 0; }

label[for='edit-field-event-location-tid-selective'] {
  float: left;
  margin: 0 20px 0 0;
  line-height: 50px; }

.views-widget-filter-field_event_location_tid_selective {
  float: none; }
  .views-widget-filter-field_event_location_tid_selective .views-widget {
    float: left; }

#views-exposed-form-Events-Cal-panel-pane-1 .views-submit-button {
  padding: 0; }

#views-exposed-form-Events-Cal-panel-pane-1 .views-exposed-widgets {
  margin-bottom: 0px; }

.pane-views-exp-events-cal-panel-pane-1 {
  margin-bottom: 0 !important; }

.calendar-calendar .week-view .full .days {
  width: 14%; }

th.margin-right, td.margin-right {
  display: none;
  width: 0; }

.calendar-calendar .week-view .full td:nth-child(2) {
  width: 14.1%; }

.view-id-news.view-display-id-panel_pane_1 > .view-content {
  max-height: 710px;
  overflow: scroll; }

.calendar-calendar td .inner div.day {
  font-size: 13px; }

.calendar-calendar td .inner div.day a {
  pointer-events: none;
  cursor: default;
  color: inherit;
  font-weight: normal; }

.calendar-calendar .month-view .full td.date-box.today, .calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
  border-color: #e87722; }

.calendar-calendar .month-view .full tr td.single-day.today {
  border-width: 4px; }

.calendar-calendar .month-view .full td.date-box.today {
  border-width: 4px 4px 0px 4px; }

.date-display-single {
  margin-right: 4px; }

.pane-events-calendar-panel-pane-1, .pane-events-calendar-panel-pane-2 {
  float: left;
  width: 100%; }
  .pane-events-calendar-panel-pane-1 .view-content .calendar-calendar, .pane-events-calendar-panel-pane-2 .view-content .calendar-calendar {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    -webkit-overflow-scrolling: touch; }

.calendar-calendar .month-view .full td.single-day div.monthview {
  padding: 13px; }

.calendar-calendar .month-view .full td.single-day .calendar-empty,
.calendar-calendar .month-view .full td.single-day.empty,
.calendar-calendar .month-view .full td.date-box.empty {
  background-color: #fff; }
  .calendar-calendar .month-view .full td.single-day .calendar-empty .day,
  .calendar-calendar .month-view .full td.single-day.empty .day,
  .calendar-calendar .month-view .full td.date-box.empty .day {
    color: #d1d2d4; }

.calendar-calendar th.days {
  background-color: #fff;
  border: 0;
  border-top: 7px solid #e8e9ea;
  height: 38px;
  line-height: 38px;
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-weight: normal; }

#edit-field-event-category-tid-wrapper {
  margin-bottom: 10px;
  padding-right: 0;
  position: relative;
  padding-top: 0; }
  @media screen and (min-width: 992px) {
    #edit-field-event-category-tid-wrapper {
      float: right;
      margin-bottom: 0; } }
  #edit-field-event-category-tid-wrapper label {
    float: left;
    font-weight: normal;
    line-height: 43px;
    height: 43px;
    margin: 0 20px 0 0; }
  #edit-field-event-category-tid-wrapper .views-widget {
    float: left; }

.weekmonthtabs ul {
  width: 100%;
  float: left;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #d7d8d9; }
  .weekmonthtabs ul li {
    float: left;
    width: 216px;
    height: 60px;
    text-align: center; }
    .weekmonthtabs ul li a {
      line-height: 60px;
      height: 60px;
      width: 100%;
      float: left;
      color: #d5d6d7;
      font-weight: 600;
      font-size: 22px;
      border-top: 4px solid #d7d8d9;
      border-right: 1px solid #d7d8d9;
      background-color: #f5f6f7; }
    .weekmonthtabs ul li a.active span {
      display: none; }
    .weekmonthtabs ul li a.active {
      top: 1px;
      height: 61px;
      background-color: #fff;
      color: #000; }

@media screen and (min-width: 992px) {
  #views-exposed-form-events-calendar-panel-pane-1, #views-exposed-form-events-calendar-panel-pane-2 {
    float: right;
    height: 0; } }

.calendar-calendar .month-view .full td.single-day .inner > div {
  border-left: 5px solid #fdc326; }

.calendar-calendar td span.date-display-single, .calendar-calendar td span.date-display-start, .calendar-calendar td span.date-display-end, .calendar-calendar td span.date-display-separator, .calendar-calendar td a {
  font-weight: normal;
  font-size: 13px;
  display: inline; }

.today .inner > div {
  border-left: none !important; }

#primary-nav .level-1 {
  width: 16.66667%;
  padding: 8px 1.6%; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: auto;
      padding: 8px 0.83%; } }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      padding: 8px 2.14%; } }

#drawer-nav .level-2 {
  padding-right: 15px; }

@media screen and (min-width: 992px) {
  #drawer-nav .level-2.menu-mlid-1950 {
    width: 22%; } }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.brand__lockup img {
  max-height: 84px; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/* Homepage Style */
.tagline {
  margin: 25px 0 8px; }
  .tagline p {
    text-align: center;
    line-height: 36px;
    font-size: 20px;
    color: #777; }

.socrow {
  margin: 0 auto;
  width: 205px; }
  .socrow a {
    border-bottom: none; }
    .socrow a:last-child span {
      margin-right: 0; }
  .socrow svg {
    width: 28px;
    height: 28px;
    fill: #fff;
    float: left; }
  .socrow span {
    font-size: 14px;
    height: 28px;
    margin-right: 26px;
    margin-left: 6px;
    float: left; }

/* Homepage Tabs */
.pane-homepage-cta-tabs-panel-pane-2 {
  display: block;
  float: left;
  width: 100%;
  margin-top: -30px;
  top: 60px;
  position: relative;
  z-index: 100; }
  .pane-homepage-cta-tabs-panel-pane-2 .view-content {
    width: 100%;
    margin: 0 auto; }
  .pane-homepage-cta-tabs-panel-pane-2 .lnk-row {
    background-color: #cf4520;
    float: left;
    width: 100%;
    padding: 0 20px;
    position: relative; }
    @media screen and (min-width: 768px) {
      .pane-homepage-cta-tabs-panel-pane-2 .lnk-row {
        padding: 0 55px; } }
    @media screen and (min-width: 992px) {
      .pane-homepage-cta-tabs-panel-pane-2 .lnk-row {
        width: 11%;
        padding: 0; }
        .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:nth-child(2) {
          width: 18%; }
        .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:nth-child(3) {
          width: 33%; }
        .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:nth-child(4) {
          width: 38%; } }
    @media screen and (min-width: 1200px) {
      .pane-homepage-cta-tabs-panel-pane-2 .lnk-row {
        width: 18%;
        padding: 0; }
        .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:nth-child(3) {
          width: 30%; }
        .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:nth-child(4) {
          width: 34%; } }
    .pane-homepage-cta-tabs-panel-pane-2 .lnk-row:last-child a {
      border-right: 0; }
  .pane-homepage-cta-tabs-panel-pane-2 .onlnk {
    background-color: #cf4520; }
    @media screen and (min-width: 992px) {
      .pane-homepage-cta-tabs-panel-pane-2 .onlnk {
        background-color: transparent; }
        .pane-homepage-cta-tabs-panel-pane-2 .onlnk:after {
          content: ' ';
          width: 0;
          height: 0;
          border-left: 35px solid transparent;
          border-right: 35px solid transparent;
          border-top: 15px solid #fff;
          position: relative;
          margin: 0 auto;
          display: block; } }
    .pane-homepage-cta-tabs-panel-pane-2 .onlnk a {
      color: #fff; }
  .pane-homepage-cta-tabs-panel-pane-2 a {
    text-align: left;
    display: block;
    float: left;
    font-size: 16px;
    color: #ffc72c;
    line-height: 40px;
    padding: 12px 0 8px;
    width: 100%;
    border-bottom: 1px solid #b31b1b; }
    @media screen and (min-width: 992px) {
      .pane-homepage-cta-tabs-panel-pane-2 a {
        border-right: 1px solid #b31b1b;
        border-bottom: none;
        text-align: center; } }

.pane-homepage-cta-tabs-panel-pane-1 {
  float: left; }
  .pane-homepage-cta-tabs-panel-pane-1 .view-content div:first-child .bodytb {
    display: block; }
  .pane-homepage-cta-tabs-panel-pane-1 .bodytb {
    display: none;
    min-height: 404px;
    background-size: cover;
    background-image: url("../img/homepage_researchers.jpg");
    background-position: center top;
    margin: 0; }
    .pane-homepage-cta-tabs-panel-pane-1 .bodytb h2 {
      color: #fff;
      font-size: 38px;
      padding-top: 80px; }
      @media screen and (min-width: 768px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb h2 {
          padding-top: 70px; } }
      @media screen and (min-width: 992px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb h2 {
          padding-left: 72px;
          padding-top: 140px; } }
    .pane-homepage-cta-tabs-panel-pane-1 .bodytb p {
      color: #fff;
      font-size: 21px;
      line-height: 36px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb p {
          padding-left: 72px; } }
    .pane-homepage-cta-tabs-panel-pane-1 .bodytb .lefttxt,
    .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links {
      padding: 0 20px; }
      @media screen and (min-width: 768px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .lefttxt,
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links {
          padding: 0 55px; } }
      @media screen and (min-width: 992px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .lefttxt,
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links {
          padding: 0 20px; } }
    .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links {
      min-height: 220px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links {
          padding-top: 0;
          margin-top: 120px;
          border-top: none;
          border-left: 1px solid #e7751d; } }
      .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a {
        font-size: 18px;
        line-height: 37px;
        width: 100%;
        display: block;
        color: #ffc72c;
        clear: both; }
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a:after {
          content: '\e80d';
          color: #e7751d;
          padding-left: 10px;
          font-size: 12px;
          vertical-align: top;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          text-align: center;
          /* opacity: .8; */
          /* For safety - reset parent styles, that can break glyph codes*/
          font-variant: normal;
          text-transform: none;
          /* fix buttons height, for twitter bootstrap */
          line-height: 1em;
          /* Animation center compensation - margins should be symmetric */
          /* remove if not needed */
          /* you can be more comfortable with increased icons size */
          /* font-size: 120%; */
          /* Font smoothing. That was taken from TWBS */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          /* Uncomment for 3D effect */
          /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
          text-decoration: none;
          line-height: 3; }
        .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a:first-child {
          border-top: 1px solid #e7751d;
          padding-top: 20px; }
          .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a:first-child:before {
            position: absolute;
            top: 0px;
            left: 50%;
            content: ' ';
            width: 0;
            height: 0;
            border-top: 10px solid #e7751d;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent; }
          @media screen and (min-width: 992px) {
            .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a:first-child {
              border-top: none;
              padding-top: 0; }
              .pane-homepage-cta-tabs-panel-pane-1 .bodytb .cta--links a:first-child:before {
                position: absolute;
                top: 95px;
                left: 0px;
                content: ' ';
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid #e7751d; } }
  .pane-homepage-cta-tabs-panel-pane-1 .imgurl {
    display: none; }

.btn-sml {
  border-radius: 8px;
  font-size: 13px;
  padding: 4px 32px; }

.pane-news-panel-pane-5 {
  margin-bottom: 40px;
  float: left; }
  .pane-news-panel-pane-5 .view {
    float: left; }
  .pane-news-panel-pane-5 .views-row {
    float: left;
    width: 100%; }
    @media screen and (min-width: 992px) {
      .pane-news-panel-pane-5 .views-row {
        border-right: 1px solid #f2f3f3;
        padding-right: 65px; } }
  .pane-news-panel-pane-5 h3.pane-title {
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    margin-top: 25px;
    margin-bottom: 20px;
    float: left;
    font-weight: 700; }
  .pane-news-panel-pane-5 h3 {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    float: left;
    margin: 0 0 8px; }
    .pane-news-panel-pane-5 h3 a {
      color: #cf4520;
      margin: 0; }
      .pane-news-panel-pane-5 h3 a:after {
        line-height: 2.3; }
  .pane-news-panel-pane-5 .teaser-image {
    margin-bottom: 5px; }
    .pane-news-panel-pane-5 .teaser-image img {
      width: 100%; }
  .pane-news-panel-pane-5 p.post-date {
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    width: 100%;
    float: left;
    margin-bottom: 20px;
    font-weight: 600; }
  .pane-news-panel-pane-5 .view-footer p {
    text-align: right; }
    .pane-news-panel-pane-5 .view-footer p a {
      color: #e7751d;
      margin-right: 45px; }
      .pane-news-panel-pane-5 .view-footer p a:after {
        content: '\e80d';
        color: #e87722;
        padding-left: 10px;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        text-decoration: none;
        line-height: 2.5; }
  @media screen and (min-width: 992px) {
    .pane-news-panel-pane-5 .view-footer {
      border-right: 1px solid #f2f3f3; } }

.pane-events-panel-pane-1 .views-row,
.pane-events-panel-pane-2 .views-row,
.pane-events-panel-pane-3 .views-row,
.pane-events-panel-pane-4 .views-row {
  width: 100%;
  float: left;
  margin: 15px 0; }

.pane-events-panel-pane-1 h3.pane-title,
.pane-events-panel-pane-2 h3.pane-title,
.pane-events-panel-pane-3 h3.pane-title,
.pane-events-panel-pane-4 h3.pane-title {
  float: left;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  margin-top: 42px;
  margin-bottom: 20px;
  width: 25%;
  text-align: right;
  padding-right: 15px; }

.pane-events-panel-pane-1 .views-field-title,
.pane-events-panel-pane-2 .views-field-title,
.pane-events-panel-pane-3 .views-field-title,
.pane-events-panel-pane-4 .views-field-title {
  float: left;
  width: 75%;
  display: table;
  height: 62px; }
  .pane-events-panel-pane-1 .views-field-title h3,
  .pane-events-panel-pane-2 .views-field-title h3,
  .pane-events-panel-pane-3 .views-field-title h3,
  .pane-events-panel-pane-4 .views-field-title h3 {
    display: table-cell;
    vertical-align: middle;
    margin-top: 0;
    padding-left: 15px; }
    .pane-events-panel-pane-1 .views-field-title h3 a,
    .pane-events-panel-pane-2 .views-field-title h3 a,
    .pane-events-panel-pane-3 .views-field-title h3 a,
    .pane-events-panel-pane-4 .views-field-title h3 a {
      color: #cf4520;
      font-size: 16px; }
      .pane-events-panel-pane-1 .views-field-title h3 a:after,
      .pane-events-panel-pane-2 .views-field-title h3 a:after,
      .pane-events-panel-pane-3 .views-field-title h3 a:after,
      .pane-events-panel-pane-4 .views-field-title h3 a:after {
        top: 9px;
        position: relative; }

.pane-events-panel-pane-1 .views-field-field-event-category,
.pane-events-panel-pane-1 .date-and-category,
.pane-events-panel-pane-2 .views-field-field-event-category,
.pane-events-panel-pane-2 .date-and-category,
.pane-events-panel-pane-3 .views-field-field-event-category,
.pane-events-panel-pane-3 .date-and-category,
.pane-events-panel-pane-4 .views-field-field-event-category,
.pane-events-panel-pane-4 .date-and-category {
  float: left;
  width: 25%;
  max-width: 150px;
  border-right: 1px solid #d7d8d9;
  min-height: 62px; }
  .pane-events-panel-pane-1 .views-field-field-event-category a,
  .pane-events-panel-pane-1 .date-and-category a,
  .pane-events-panel-pane-2 .views-field-field-event-category a,
  .pane-events-panel-pane-2 .date-and-category a,
  .pane-events-panel-pane-3 .views-field-field-event-category a,
  .pane-events-panel-pane-3 .date-and-category a,
  .pane-events-panel-pane-4 .views-field-field-event-category a,
  .pane-events-panel-pane-4 .date-and-category a {
    color: #6b6c6d;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    padding-right: 15px;
    float: left;
    width: 100%; }
  .pane-events-panel-pane-1 .views-field-field-event-category .date-display-single,
  .pane-events-panel-pane-1 .date-and-category .date-display-single,
  .pane-events-panel-pane-2 .views-field-field-event-category .date-display-single,
  .pane-events-panel-pane-2 .date-and-category .date-display-single,
  .pane-events-panel-pane-3 .views-field-field-event-category .date-display-single,
  .pane-events-panel-pane-3 .date-and-category .date-display-single,
  .pane-events-panel-pane-4 .views-field-field-event-category .date-display-single,
  .pane-events-panel-pane-4 .date-and-category .date-display-single {
    color: #6b6c6d;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 26px;
    font-weight: 600;
    text-align: right;
    padding-right: 15px;
    float: left;
    width: 100%; }

.pane-events-panel-pane-1 .view-footer,
.pane-events-panel-pane-2 .view-footer,
.pane-events-panel-pane-3 .view-footer,
.pane-events-panel-pane-4 .view-footer {
  width: 100%;
  float: left;
  margin-bottom: 50px; }
  .pane-events-panel-pane-1 .view-footer a,
  .pane-events-panel-pane-2 .view-footer a,
  .pane-events-panel-pane-3 .view-footer a,
  .pane-events-panel-pane-4 .view-footer a {
    color: #e7751d;
    float: right;
    text-align: right; }
    .pane-events-panel-pane-1 .view-footer a:after,
    .pane-events-panel-pane-2 .view-footer a:after,
    .pane-events-panel-pane-3 .view-footer a:after,
    .pane-events-panel-pane-4 .view-footer a:after {
      content: '\e80d';
      color: #e87722;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: top;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      text-decoration: none;
      line-height: 2.5; }

.pane-events-panel-pane-2 h3.pane-title,
.pane-events-panel-pane-3 h3.pane-title,
.pane-events-panel-pane-4 h3.pane-title {
  width: 100%;
  text-align: left;
  margin-top: 22px;
  color: #b31b1b;
  font-size: 19px;
  text-transform: none;
  margin-bottom: 0; }

.pane-events-panel-pane-2 .views-field-field-event-category,
.pane-events-panel-pane-3 .views-field-field-event-category,
.pane-events-panel-pane-4 .views-field-field-event-category {
  width: 10%;
  min-width: 80px; }

.pane-events-panel-pane-2 .view-footer a,
.pane-events-panel-pane-3 .view-footer a,
.pane-events-panel-pane-4 .view-footer a {
  float: left; }

.hottofw {
  background-color: #f6f7f8; }

.howto-pane {
  width: 100%;
  float: left;
  margin-bottom: 21px; }
  .howto-pane h3.pane-title {
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    margin-top: 29px;
    margin-bottom: 10px;
    text-align: center; }
  .howto-pane .ctsc-howto__tile {
    margin-right: 2%;
    width: 48%;
    float: left;
    background-color: #fff;
    height: 160px;
    font-size: 12px;
    line-height: 24px;
    backface-visibility: hidden;
    border: 3px solid #fff;
    margin-bottom: 10px;
    padding: 25px; }
    @media screen and (min-width: 992px) {
      .howto-pane .ctsc-howto__tile {
        margin-right: 20px;
        width: 18%;
        padding: 25px 45px;
        margin: 9px 20px 9px 0; } }
    @media screen and (min-width: 1200px) {
      .howto-pane .ctsc-howto__tile {
        width: 18.5%;
        padding: 25px 65px;
        margin: 9px 20px 9px 0; } }
    .howto-pane .ctsc-howto__tile:last-child {
      margin-right: 0;
      margin-left: 25%; }
      @media screen and (min-width: 992px) {
        .howto-pane .ctsc-howto__tile:last-child {
          margin-left: auto; } }
    .howto-pane .ctsc-howto__tile:hover {
      border: 3px solid #e7751d;
      text-decoration: none; }
    .howto-pane .ctsc-howto__tile .cta-icon {
      width: 50px;
      height: 50px;
      fill: #e87722;
      color: #cf4520;
      margin: 0 auto;
      display: block;
      float: none; }
    .howto-pane .ctsc-howto__tile > span {
      display: inline-block;
      width: 100%;
      clear: both; }
      .howto-pane .ctsc-howto__tile > span > span {
        text-align: center;
        display: inline-block;
        width: 100%;
        margin-top: 10px; }

.fwbpan h3 {
  color: #fff;
  font-size: 38px;
  text-align: center;
  margin-bottom: 22px; }

.fwbpan .fieldable-panels-pane {
  margin-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .fwbpan .fieldable-panels-pane {
      margin-bottom: 0; } }

.fwbpan ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .fwbpan ul li {
    display: inline;
    text-align: center; }
    .fwbpan ul li a.btn {
      text-align: center;
      background-color: #fff;
      border: 4px solid #d5d9d9;
      padding: 9px 20px;
      margin: 7px;
      min-width: 215px; }
      .fwbpan ul li a.btn:hover, .fwbpan ul li a.btn:focus {
        color: #e7751d;
        border: 4px solid #e7751d; }

.upper-right > .panel-pane {
  margin-top: 27px;
  float: left;
  width: 100%; }

.awardsinfo h2 {
  text-align: center;
  color: #7a7b7c;
  font-size: 24px;
  font-family: "Open Sans", sans-serrif;
  font-weight: bold;
  margin-top: 26px; }

.awardsinfo p.tots {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px; }

.awardsinfo ul.legend {
  padding: 0;
  margin: 0 auto;
  display: block;
  list-style: none;
  max-width: 230px; }
  @media screen and (min-width: 992px) {
    .awardsinfo ul.legend {
      margin: 0; } }
  .awardsinfo ul.legend em {
    font-style: normal; }

.awardsinfo .numberval {
  font-weight: 600;
  min-width: 53px;
  text-align: right;
  display: inline-block; }

.awardsinfo .pilota:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: cornflowerblue;
  margin: 0 5px;
  top: 3px;
  position: relative; }

.awardsinfo .plana:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: olivedrab;
  margin: 0 5px;
  top: 3px;
  position: relative; }

.awardsinfo .communitya:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: orange;
  margin: 0 5px;
  top: 3px;
  position: relative; }

.awardsinfo .novela:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: tomato;
  margin: 0 5px;
  top: 3px;
  position: relative; }

.awardsinfo .nta:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: crimson;
  margin: 0 5px;
  top: 3px;
  position: relative; }

.awardsinfo .rdnovela:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: purple;
  margin: 0 5px;
  top: 3px;
  position: relative; }

/* Info Graphic SASS */
@keyframes bake-pie {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0); } }

.pieID,
.pieIDed {
  display: inline-block;
  vertical-align: top; }

.pie {
  height: 152px;
  width: 152px;
  position: relative;
  margin: 0 auto 30px;
  display: block; }
  @media screen and (min-width: 992px) {
    .pie {
      margin: 0 0 30px 0;
      float: right; } }

.pie::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 76px;
  height: 76px;
  background: #EEE;
  border-radius: 50%;
  top: 38px;
  left: 38px; }

.slice,
.sliceEd {
  position: absolute;
  width: 152px;
  height: 152px;
  clip: rect(0px, 152px, 152px, 76px);
  animation: bake-pie 1s; }

.slice span,
.sliceEd span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  clip: rect(0px, 152px, 152px, 76px); }

ul.pieIDed.legend,
ul.pieID.legend {
  margin-bottom: 20px; }

/* Info Grpahic SASS end */
.information-sidebar .btn--wcm {
  margin-bottom: 20px;
  padding: 10px 20px; }
  .information-sidebar .btn--wcm:before {
    line-height: 2.5; }

.information-sidebar .how_dropdown {
  margin-bottom: 32px; }
  .information-sidebar .how_dropdown h3 {
    color: #000;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px; }
    .information-sidebar .how_dropdown h3 label {
      margin-bottom: 0; }
  .information-sidebar .how_dropdown .select2-container {
    width: 100% !important;
    margin-bottom: 32px; }

.information-sidebar .socrow {
  width: 100%;
  float: left; }
  .information-sidebar .socrow .cpsoc {
    display: table;
    margin: 0 auto 20px;
    width: 125px; }

a.cpsoc.external-link:after {
  display: none; }

/* Leadership Pane */
.pane-profiles-panel-pane-3 .views-row,
.pane-profiles-panel-pane-4 .views-row,
.pane-profiles-panel-pane-5 .views-row,
.pane-profiles-panel-pane-6 .views-row {
  display: inline-block;
  clear: both;
  width: 100%;
  border: 1px solid #f5f5f5;
  padding: 10px 30px;
  margin-bottom: 20px; }
  .pane-profiles-panel-pane-3 .views-row .views-label,
  .pane-profiles-panel-pane-4 .views-row .views-label,
  .pane-profiles-panel-pane-5 .views-row .views-label,
  .pane-profiles-panel-pane-6 .views-row .views-label {
    display: inline; }
  .pane-profiles-panel-pane-3 .views-row .field-content,
  .pane-profiles-panel-pane-4 .views-row .field-content,
  .pane-profiles-panel-pane-5 .views-row .field-content,
  .pane-profiles-panel-pane-6 .views-row .field-content {
    display: inline; }
  .pane-profiles-panel-pane-3 .views-row .views-field,
  .pane-profiles-panel-pane-4 .views-row .views-field,
  .pane-profiles-panel-pane-5 .views-row .views-field,
  .pane-profiles-panel-pane-6 .views-row .views-field {
    margin-left: 20px; }
    @media screen and (min-width: 992px) {
      .pane-profiles-panel-pane-3 .views-row .views-field,
      .pane-profiles-panel-pane-4 .views-row .views-field,
      .pane-profiles-panel-pane-5 .views-row .views-field,
      .pane-profiles-panel-pane-6 .views-row .views-field {
        margin-left: 0; } }
  .pane-profiles-panel-pane-3 .views-row .views-field-field-profile-image,
  .pane-profiles-panel-pane-4 .views-row .views-field-field-profile-image,
  .pane-profiles-panel-pane-5 .views-row .views-field-field-profile-image,
  .pane-profiles-panel-pane-6 .views-row .views-field-field-profile-image {
    display: block;
    float: left;
    margin-right: 35px;
    margin-left: 0; }
  .pane-profiles-panel-pane-3 .views-row h3.display-name,
  .pane-profiles-panel-pane-4 .views-row h3.display-name,
  .pane-profiles-panel-pane-5 .views-row h3.display-name,
  .pane-profiles-panel-pane-6 .views-row h3.display-name {
    color: #4b4b4b;
    font-size: 15px; }
  .pane-profiles-panel-pane-3 .views-row .views-field-field-biographical-info .field-content,
  .pane-profiles-panel-pane-4 .views-row .views-field-field-biographical-info .field-content,
  .pane-profiles-panel-pane-5 .views-row .views-field-field-biographical-info .field-content,
  .pane-profiles-panel-pane-6 .views-row .views-field-field-biographical-info .field-content {
    max-height: 75px; }
  .pane-profiles-panel-pane-3 .views-row a.exmore:hover,
  .pane-profiles-panel-pane-4 .views-row a.exmore:hover,
  .pane-profiles-panel-pane-5 .views-row a.exmore:hover,
  .pane-profiles-panel-pane-6 .views-row a.exmore:hover {
    text-decoration: none; }
    .pane-profiles-panel-pane-3 .views-row a.exmore:hover span,
    .pane-profiles-panel-pane-4 .views-row a.exmore:hover span,
    .pane-profiles-panel-pane-5 .views-row a.exmore:hover span,
    .pane-profiles-panel-pane-6 .views-row a.exmore:hover span {
      text-decoration: none; }
  .pane-profiles-panel-pane-3 .views-row a.exmore:before,
  .pane-profiles-panel-pane-4 .views-row a.exmore:before,
  .pane-profiles-panel-pane-5 .views-row a.exmore:before,
  .pane-profiles-panel-pane-6 .views-row a.exmore:before {
    content: "...";
    color: #000000;
    display: inline-block;
    width: 100%; }
  .pane-profiles-panel-pane-3 .views-row a.exmore span,
  .pane-profiles-panel-pane-4 .views-row a.exmore span,
  .pane-profiles-panel-pane-5 .views-row a.exmore span,
  .pane-profiles-panel-pane-6 .views-row a.exmore span {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    top: 4px;
    text-decoration: none; }
  .pane-profiles-panel-pane-3 .views-row a.exmore.cls:before,
  .pane-profiles-panel-pane-4 .views-row a.exmore.cls:before,
  .pane-profiles-panel-pane-5 .views-row a.exmore.cls:before,
  .pane-profiles-panel-pane-6 .views-row a.exmore.cls:before {
    content: " ";
    display: none; }

.views-field-field-biographical-info .field-content + [data-readmore-toggle],
.views-field-field-biographical-info .field-content[data-readmore] {
  width: auto !important; }

.pane-profiles-panel-pane-4 .views-row .views-field {
  margin-left: 0; }
  .pane-profiles-panel-pane-4 .views-row .views-field a {
    border-bottom: none; }

.pane-profiles-panel-pane-4 div[class*='col-sm'] {
  padding-left: 0;
  padding-right: 0; }

@media screen and (min-width: 768px) {
  .pane-profiles-panel-pane-4 .col-sm-9 {
    padding-left: 35px !important; } }

.pane-profiles-panel-pane-6 .views-row .views-field {
  margin-left: 0; }
  .pane-profiles-panel-pane-6 .views-row .views-field a {
    border-bottom: none; }

.pane-profiles-panel-pane-6 div[class*='col-sm'] {
  padding-left: 0;
  padding-right: 0; }

.pane-profiles-panel-pane-6 .col-sm-3.no-profile-image {
  display: none; }

@media screen and (min-width: 768px) {
  .pane-profiles-panel-pane-6 .col-sm-3.no-profile-image + .col-sm-9 {
    padding-left: 0px !important;
    width: 100%; } }

@media screen and (min-width: 768px) {
  .pane-profiles-panel-pane-6 .col-sm-9 {
    padding-left: 35px !important; } }

.pane-profiles-panel-pane-5 .views-row {
  border-width: 0 0 1px;
  padding: 0 0 30px; }

.pane-profiles-panel-pane-5 .views-row:last-child {
  border: none; }

/**/
/*News page*/
.calendar-link-container {
  border-width: 3px 0;
  border-color: #ddd;
  border-style: solid;
  padding: 20px 0; }
  .calendar-link-container .calendar-link-right {
    padding-left: 3rem;
    border-left: 3px solid #ddd; }
    .calendar-link-container .calendar-link-right .calendar-link-title {
      font-family: '1898Sans-Bold';
      font-size: 22px; }
      .calendar-link-container .calendar-link-right .calendar-link-title:after {
        content: '\e80d';
        color: #e7751d;
        padding-left: 0;
        font-size: 60%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
        text-decoration: none;
        line-height: 2.5; }
    .calendar-link-container .calendar-link-right .calendar-link-body {
      font-size: 16px;
      color: #7a7a7a; }
  .calendar-link-container .calendar-link-left {
    padding-top: 0;
    padding: 0 18px; }
    .calendar-link-container .calendar-link-left svg {
      width: 60px;
      height: 60px;
      display: block;
      fill: #e7751d; }

.pane-news-panel-pane-6 .views-row {
  float: left;
  width: 100%;
  border-bottom: 1px solid #eeeff0;
  padding: 25px 0; }
  .pane-news-panel-pane-6 .views-row:first-child {
    padding-top: 5px; }

.pane-news-panel-pane-6 h3.teaser-title {
  font-size: 18px;
  line-height: 1.5; }
  @media screen and (min-width: 768px) {
    .pane-news-panel-pane-6 h3.teaser-title {
      margin-top: 25px; } }
  .pane-news-panel-pane-6 h3.teaser-title a:after {
    line-height: 2.5; }

.pane-news-panel-pane-6 p.post-date {
  text-transform: uppercase;
  color: #68696a;
  font-size: 12px;
  font-weight: bold; }

.pane-news-panel-pane-6 .teaser-text p {
  font-size: 16px;
  line-height: 25px; }

.pane-news-panel-pane-6 .teaser-text a:after {
  content: '\e80d';
  color: #e87722;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  text-decoration: none;
  line-height: 3; }

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-6 .teaser-image {
    float: right;
    margin-top: 25px; } }

.post-date {
  text-transform: uppercase;
  color: #68696a;
  font-size: 12px;
  font-weight: bold; }

.pane-node-webform input.btn {
  border-radius: 8px;
  font-size: 13px;
  padding: 4px 32px; }

.pane-ctsc-demogstats {
  background-color: #f7f7f7; }
  .pane-ctsc-demogstats h3 {
    font-size: 20px;
    color: #b31b1b;
    text-align: center;
    margin-top: 40px;
    float: left;
    width: 100%; }
  .pane-ctsc-demogstats .pie {
    margin-bottom: 40px; }

.pane-research-resources-panel-pane-1 h3.pane-title {
  margin: 30px 0 5px; }

.vrt {
  display: block;
  background-color: #f6f7f8;
  width: 100%;
  min-height: 312px;
  margin: 60px 0 30px; }
  .vrt span.logo {
    font-size: 52px;
    text-align: center;
    float: left;
    width: 100%;
    margin-top: 62px; }
    .vrt span.logo svg {
      max-width: 96px; }
  .vrt span.viewl {
    position: absolute;
    bottom: 45px;
    right: 32px;
    line-height: 24px; }
    .vrt span.viewl svg {
      width: 24px;
      height: 24px;
      fill: #e7751d;
      top: 7px;
      position: relative;
      margin-left: 5px; }
  .vrt h3 {
    text-align: center; }

.appedbtns {
  float: left;
  width: 100%; }
  .appedbtns .btn {
    padding: 10px 20px;
    margin-bottom: 20px; }
    .appedbtns .btn:before {
      line-height: 2.5; }

.pane-programs-panel-pane-1 h3.pane-title {
  margin: 15px 0 10px; }

.pane-programs-panel-pane-1 a.views-more-link {
  display: inline-block;
  color: #fff;
  background-color: #cf4520;
  padding: 4px 30px;
  border-radius: 8px;
  margin: 15px 0 20px; }

.pane-wcmc-second-level-nav {
  float: left;
  width: 100%; }

.inquiries h3 {
  margin: 0;
  line-height: 32px; }
  .inquiries h3:before {
    background-position: -600px 0px;
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    float: left;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
    background-size: 632px 152px; }

.inquiries a {
  color: #e7751d; }

.pane-node-field-prog-related-links > div {
  list-style: outside; }
  .pane-node-field-prog-related-links > div a {
    width: 100%;
    float: left;
    color: #e7751d; }

.applbtn {
  float: left; }
  .applbtn a {
    padding: 10px 20px;
    color: #e7751d;
    position: relative;
    display: block;
    width: 220px;
    margin: 20px auto;
    border-radius: 20px;
    font-size: 13px;
    background-color: transparent;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 4px solid #e7751d;
    white-space: nowrap; }

.contact-info .site-name {
  margin: 0 12px; }

.pane-courses-panel-pane-1 {
  float: left;
  width: 100%; }
  .pane-courses-panel-pane-1 .field-label {
    font-weight: 700;
    display: inline; }
  .pane-courses-panel-pane-1 span.element__toggle {
    display: block; }
  .pane-courses-panel-pane-1 p {
    border-top: 1px solid #f5f6f7;
    margin: 10px 0 20px;
    padding-top: 10px; }

.coursetxt {
  background-color: #f6f7f8;
  padding: 0 25px 25px;
  display: inline-block;
  width: 100%;
  clear: both; }
  .coursetxt .taxonomy-term-description {
    margin: 0; }
    .coursetxt .taxonomy-term-description p {
      margin: 2px 0 12.5px;
      padding: 0; }

.pane-views-exp-courses-panel-pane-1 {
  background-color: #f6f7f8;
  padding: 0 25px 0;
  float: left;
  width: 100%; }
  .pane-views-exp-courses-panel-pane-1 label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 10px; }
  .pane-views-exp-courses-panel-pane-1 select.form-select {
    max-width: none; }

.pane-events-panel-pane-3 .view-display-id-panel_pane_1 {
  float: left;
  width: 100%; }

.element--show-hide:last-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px; }

.ctaban {
  display: block;
  width: 100%; }

.comorp {
  background: url(../img/drpat.jpg) 0 0 no-repeat;
  background-size: 100% auto;
  background-color: #f6f7f8;
  padding: 300px 30px 30px; }
  .comorp h3 a {
    font-size: 16px;
    color: #cf4520; }
    .comorp h3 a:after {
      line-height: 2.5; }
  .comorp h3.pane-title {
    margin-top: 0;
    margin-bottom: 14px; }
    .comorp h3.pane-title a:after {
      line-height: 2.5; }
  @media screen and (min-width: 768px) {
    .comorp {
      padding-top: 500px; } }
  @media screen and (min-width: 992px) {
    .comorp {
      padding-top: 250px; } }
  @media screen and (min-width: 1200px) {
    .comorp {
      padding-top: 300px; } }

.reccta {
  background: url(../img/comrec.jpg) 0 0 no-repeat;
  background-size: 100% auto;
  background-color: #f5f6f7;
  padding: 300px 30px 30px;
  min-height: 147px;
  margin-bottom: 24px; }
  .reccta h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 14px; }
  .reccta p a {
    font-size: 16px;
    color: #cf4520;
    font-family: "1898Sans-Bold", sans-serif;
    font-weight: normal; }
    .reccta p a:after {
      content: '\e80d';
      color: #e87722;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: top;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      text-decoration: none;
      line-height: 2.5; }
  @media screen and (min-width: 768px) {
    .reccta {
      padding-top: 500px; } }
  @media screen and (min-width: 992px) {
    .reccta {
      padding-top: 250px; } }
  @media screen and (min-width: 1200px) {
    .reccta {
      padding-top: 300px; } }

.pane-research-studies-panel-pane-1:last-child {
  border-bottom: 1px solid #ddd; }

.pane-research-studies-panel-pane-1 h3.pane-title {
  margin-bottom: 10px; }

.pane-research-studies-panel-pane-1 ul {
  margin-left: 25px; }

@media screen and (min-width: 992px) {
  .npl {
    padding-left: 0; } }

@media screen and (min-width: 992px) {
  .npr {
    padding-right: 0; } }

.h2hspon {
  float: left;
  width: 100%;
  margin-top: 18px; }
  .h2hspon h3 {
    color: #000;
    font-size: 14px;
    text-align: center; }
  .h2hspon p {
    margin: 0 0 10px; }
  .h2hspon a {
    color: #cf4520; }
    .h2hspon a:after {
      color: #e7751d; }

/* Summer Fellows Pane */
.pane-awardee-panel-pane-1 h3,
.pane-awardee-panel-pane-2 h3,
.pane-awardee-panel-pane-3 h3,
.field-collection-container h3 {
  margin-bottom: 10px;
  text-align: left; }

.pane-awardee-panel-pane-1 table,
.pane-awardee-panel-pane-2 table,
.pane-awardee-panel-pane-3 table,
.field-collection-container table {
  margin: 0 0 16px 0;
  border-top-color: #f5f6f7; }
  .pane-awardee-panel-pane-1 table thead,
  .pane-awardee-panel-pane-2 table thead,
  .pane-awardee-panel-pane-3 table thead,
  .field-collection-container table thead {
    border-color: #f5f6f7; }
    .pane-awardee-panel-pane-1 table thead tr,
    .pane-awardee-panel-pane-2 table thead tr,
    .pane-awardee-panel-pane-3 table thead tr,
    .field-collection-container table thead tr {
      border-color: #f5f6f7; }
      .pane-awardee-panel-pane-1 table thead tr th,
      .pane-awardee-panel-pane-2 table thead tr th,
      .pane-awardee-panel-pane-3 table thead tr th,
      .field-collection-container table thead tr th {
        background-color: #f5f6f7;
        border-color: #f5f6f7; }
  .pane-awardee-panel-pane-1 table tbody,
  .pane-awardee-panel-pane-2 table tbody,
  .pane-awardee-panel-pane-3 table tbody,
  .field-collection-container table tbody {
    border-color: #f5f6f7; }
    .pane-awardee-panel-pane-1 table tbody tr,
    .pane-awardee-panel-pane-2 table tbody tr,
    .pane-awardee-panel-pane-3 table tbody tr,
    .field-collection-container table tbody tr {
      background-color: #fff; }
      .pane-awardee-panel-pane-1 table tbody tr:nth-child(odd) > td,
      .pane-awardee-panel-pane-2 table tbody tr:nth-child(odd) > td,
      .pane-awardee-panel-pane-3 table tbody tr:nth-child(odd) > td,
      .field-collection-container table tbody tr:nth-child(odd) > td {
        background-color: #fff; }
      .pane-awardee-panel-pane-1 table tbody tr td,
      .pane-awardee-panel-pane-2 table tbody tr td,
      .pane-awardee-panel-pane-3 table tbody tr td,
      .field-collection-container table tbody tr td {
        border-color: #f5f6f7; }

.pane-awardee-panel-pane-1 .views-field-field-awardee-institution,
.pane-awardee-panel-pane-2 .views-field-field-awardee-institution,
.pane-awardee-panel-pane-3 .views-field-field-awardee-institution,
.field-collection-container .views-field-field-awardee-institution {
  min-width: 116px; }

.pane-awardee-panel-pane-1 td.picopi,
.pane-awardee-panel-pane-2 td.picopi,
.pane-awardee-panel-pane-3 td.picopi,
.field-collection-container td.picopi {
  min-width: 60px; }

.pane-awardee-panel-pane-1 td.views-field-field-related-co-pis,
.pane-awardee-panel-pane-2 td.views-field-field-related-co-pis,
.pane-awardee-panel-pane-3 td.views-field-field-related-co-pis,
.field-collection-container td.views-field-field-related-co-pis {
  display: none; }

.pane-node-body table {
  margin: 10px 0 16px 0;
  border-top-color: #f5f6f7; }
  .pane-node-body table thead {
    border-color: #f5f6f7; }
    .pane-node-body table thead tr {
      border-color: #f5f6f7; }
      .pane-node-body table thead tr th {
        background-color: #f5f6f7;
        border-color: #f5f6f7; }
  .pane-node-body table tr {
    border-color: #f5f6f7; }
    .pane-node-body table tr th {
      background-color: #f5f6f7;
      border-color: #f5f6f7;
      padding: 8px; }
  .pane-node-body table tbody {
    border-color: #f5f6f7; }
    .pane-node-body table tbody tr {
      background-color: #fff; }
      .pane-node-body table tbody tr:nth-child(odd) > td {
        background-color: #fff; }
      .pane-node-body table tbody tr td {
        border-color: #f5f6f7;
        border: 1px solid #f5f6f7;
        padding: 8px; }

/* Summer Fellows Pane END */
/* slider style */
.slide-text {
  background: #fff;
  opacity: .95; }
  .slide-text h2 {
    color: #555; }
    .slide-text h2 a {
      color: #555; }
  .slide-text p {
    color: #555; }

.thumbnail-controller .pager {
  margin: 14px 0;
  text-transform: none; }

.findac input[type=text] {
  min-width: 66%;
  border: 3px solid #d8d8d8;
  border-right: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 43px;
  line-height: 43px;
  padding: 0 20px; }
  @media screen and (min-width: 768px) {
    .findac input[type=text] {
      min-width: 80%; } }

.findac p {
  margin: 18px 0 5px; }

.findac span.btmtxt {
  width: 100%;
  display: block;
  margin-bottom: 20px; }

.findac a.btn {
  position: relative;
  top: -2px;
  left: -4px;
  background-color: #e7751d;
  padding: 11px 20px 7px 48px;
  margin: 0;
  border: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
  .findac a.btn svg {
    position: absolute;
    top: 12px;
    left: 12px;
    color: #fff;
    fill: #fff;
    width: 24px;
    height: 24px; }
  .findac a.btn:hover {
    background-color: #cf4520; }

iframe {
  border: 0; }

.pane-wcm-postdocs-horcta {
  border-top: solid 13px #f7f7f7;
  display: inline-block;
  width: 100%;
  margin-top: 3px;
  padding-top: 25px; }

.horctas .wcm-cta {
  float: left;
  width: 33.33%; }
  .horctas .wcm-cta a {
    float: left;
    width: 50%; }

.horctas .how_dropdown {
  width: 33.33%; }
  .horctas .how_dropdown h3 {
    color: #000;
    font-size: 14px;
    text-align: center; }
  .horctas .how_dropdown .select2-container {
    display: block;
    margin: 0 auto !important; }

.horctas .socrow {
  width: 33.33%;
  margin-top: 50px; }

.social-share__print a:before {
  content: '\e814'; }

.pane-specialized-cores-panel-pane-1 .views-row {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
  padding-bottom: 5px; }

.pane-specialized-cores-panel-pane-1 .views-field-title {
  font-size: 16px; }

#related-content-sidebar h3.pane-title {
  font-family: "Open Sans", sans-serrif; }

/* =FAQ
----------------------------------------------------------*/
.view-faq .view-content > h3 {
  font-size: 18px;
  color: #666666; }

/* =Footer
----------------------------------------------------------*/
.front footer {
  padding-top: 0; }

footer {
  padding-top: 40px; }

.footer-address__address a {
  border-bottom: 1px dotted; }

.footer-nav {
  margin: 0 0 20px; }

.edutraincar .slide-text {
  display: none; }

.lnkscta h3 {
  font-size: 16px;
  color: #000;
  padding-left: 38px; }

.callout-image {
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: block;
  width: 100%;
  min-height: 320px;
  margin-top: 50px;
  margin-bottom: 30px; }

.callout-btns {
  border-left: 1px solid #cf4520;
  padding-left: 38px;
  list-style: none;
  float: left; }
  .callout-btns li {
    float: left;
    clear: both; }
    .callout-btns li:hover {
      position: relative;
      left: -22px; }
      .callout-btns li:hover:before {
        content: '\e80d';
        position: relative;
        left: -26px;
        color: #cf4520;
        padding-left: 10px;
        font-size: 100%;
        vertical-align: top;
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        top: 5px; }
      .callout-btns li:hover a {
        border-bottom: 1px solid #e7751d;
        color: #e7751d; }
  .callout-btns li a {
    border-bottom: 1px solid #fff;
    line-height: 35px;
    font-size: 16px;
    color: #cf4520;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-decoration: none; }

.welcome {
  padding-bottom: 6px;
  padding-top: 10px; }
  .welcome .col-md-3 {
    padding-left: 0; }
  .welcome h2 {
    margin-bottom: 23px; }
  .welcome p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 30px; }
  .welcome p.caption-text {
    font-size: 15px;
    line-height: 24px;
    font-style: italic; }

.fwbpan {
  background-color: #f9fafc; }
  .fwbpan h2 {
    margin: 30px 0 22px; }
  .fwbpan .pane-consortium-panel-pane-1 {
    margin-bottom: 13px; }
  .fwbpan .btn {
    margin-bottom: 23px; }

/* RFA */
.rfacta {
  padding: 30px;
  background-color: #f6f7f8; }
  .rfacta h3 {
    text-align: center;
    padding-bottom: 15px; }
  .rfacta p {
    line-height: 26px;
    text-align: center; }
  .rfacta a.btn {
    width: 145px;
    margin: 5px auto 0;
    float: none;
    display: block;
    padding: 4px 15px; }

.view-id-rfa > div > h3 {
  font-size: 30px; }

.pane-rfa-panel-pane-1 .views-row {
  border-bottom: 1px solid #ececee; }

.pane-rfa-panel-pane-1 .views-field-field-deadline-date {
  text-transform: uppercase;
  color: #646566;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 15px; }
  .pane-rfa-panel-pane-1 .views-field-field-deadline-date .field-content {
    display: inline; }

.pane-node-field-deadline-date {
  text-transform: uppercase;
  color: #646566;
  display: inline-block;
  font-weight: bold;
  margin-bottom: 15px; }
  .pane-node-field-deadline-date .field-label,
  .pane-node-field-deadline-date .date-display-single {
    display: inline; }

.pane-node-field-application-link a {
  float: left;
  font-size: 13px;
  border: 3px solid #cf4520;
  border-radius: 18px;
  padding: 9px 25px;
  margin-top: 20px; }

.view-id-events h3 a:hover,
.view-news h3 a:hover {
  color: #e7751d; }

/*eNews*/
.pane-enews-panel-pane-1 .view-content {
  margin-top: 20px;
  display: inline-block;
  width: 100%; }
  .pane-enews-panel-pane-1 .view-content h3 {
    margin-top: 20px;
    font-size: 18px; }
  .pane-enews-panel-pane-1 .view-content .views-field-created {
    font-weight: bold;
    font-size: 14px;
    color: #777879;
    text-transform: uppercase;
    margin-top: 1px; }
  .pane-enews-panel-pane-1 .view-content .views-row {
    border-bottom: 1px solid #eeeff0;
    padding-bottom: 17px; }

.node-ctep-news h3 {
  font-size: 30px; }

.node-ctep-news .pane-node-created {
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  display: inline;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-block; }
  .node-ctep-news .pane-node-created h3 {
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    display: inline; }

.sfatab td {
  min-width: 60px;
  padding: 5px; }
